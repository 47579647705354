import React, { useEffect, useState } from "react"
import Grid from '@mui/material/Grid';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { LocalImages } from "../../../utils/images"
import Utils from "../../../utils"
import { useDispatch, useSelector } from "react-redux";
import { getInsPaymentReview, initiatePayment } from "../action"
import { ReducersModal } from "../../../modal"
import { useNavigate } from "react-router";
import { Button, Paper, Dialog, DialogTitle } from "@mui/material";
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    maxHeight: "90vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 0,
};
const IndexReview = () => {
    const [showConfermationModal, setShowConfermationModal] = useState(false)
    const navigate = useNavigate()
    const { inurancePayReviewList, totalAmount } = useSelector(
        (state: ReducersModal) => state.insurancePaymentReducer
    );
    const dispatch = useDispatch()
    const option = localStorage.getItem("option") || "";

    useEffect(() => {
        dispatch(getInsPaymentReview())
        if (localStorage.getItem("roleId") && (localStorage.getItem("roleId") === "1")) {
            
            navigate(Utils.routes.dashboard)
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Paper
                    component="form"
                    sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: 300,
                        boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",

                    }}
                    style={{ margin: "10px 0pxs" }}
                >
                    <h3 style={{ marginRight: '5px' }}>Amount Payable :</h3> <b style={{ fontSize: 18 }}> {totalAmount ? parseFloat(
                        totalAmount
                    ).toFixed(2) : "0"} KES</b></Paper>
                <Button
                    size="large"
                    variant="contained"
                    sx={{
                        color: "var(--white)",
                        maxWidth: "180px",
                        minWidth: "80px",
                    }}
                    style={{ fontSize: 13 }}
                    onClick={() => dispatch(initiatePayment(setShowConfermationModal))}
                >
                    Initiate Payment
                </Button>
                {/* <Button onClick={() => dispatch(initiatePayment())}>Initiate Payment</Button> */}
            </div>
            <>
                {inurancePayReviewList?.map((data: any, i: number) =>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        <div className="bg-white">
                            <h3 className="mb-10">{`${++i}. ${option === "2" ? "Order" : "Claim"} ID: ${data.id}`}</h3>
                            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <h4>{`${option === "2" ? "Order" : "Claim"} Amount`}</h4>
                                    <p>{data?.full_amount ? Number(data?.full_amount).toFixed(2) + " KES" : "N/A"}</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <h4>Platform Access Fees</h4>
                                    <p>{data?.pharmacy_percent_amount ? `${data?.pharmacy_percent_amount.toFixed(2)} KES (${data.pharmacy_percent}%)` : "N/A"}</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <h4>Transaction Cost</h4>
                                    <p>{data?.transaction_cost ? `${data?.transaction_cost.toFixed(2)} KES (${data?.transaction_percent}%)` : "N/A"}</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <h4>Livia Commission</h4>
                                    <p>{data?.livia_fixed_fee ? `${Number(data?.livia_fixed_fee).toFixed(2)} KES` : "N/A"}</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <h4>Net Available</h4>
                                    <p>{data?.final_amount ? `${data?.final_amount.toFixed(2)} KES` : "N/A"}</p>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>)}
                <div style={{ padding: 10 }}>
                    <Dialog
                        open={showConfermationModal}
                        onClose={() => setShowConfermationModal(false)}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <Box sx={{ display: "flex" }}>
                            <DialogTitle
                                sx={{
                                    padding: 0,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    flexBasis: "92%",
                                }}
                                id="responsive-dialog-title"
                            >
                                {"Thank You"}
                            </DialogTitle>
                            <Box sx={{ flexBasis: "8%", textAlign: "center", cursor: "pointer" }}>
                                <img src={LocalImages.CloseIcon} alt="" onClick={() => setShowConfermationModal(false)} />
                            </Box>

                        </Box>
                        <p style={{ padding: 20 }}>Payment Process Initiated Successfully, Please come back after some time to see the payment status.</p>
                        <Button
                            type="button"
                            sx={[
                                {
                                    color: "var(--white)",
                                },
                            ]}
                            variant="contained"
                            color="primary"
                            style={{ width: 100, margin: "10px auto 10px" }}
                            onClick={() => { setShowConfermationModal(false); navigate(Utils.routes.parentCompany); }}
                        >
                            Ok
                        </Button>

                    </Dialog>
                </div>
            </>
        </React.Fragment>

    )
}
export default IndexReview



