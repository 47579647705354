import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReducersModal } from "../../../modal";
import { confirmPrescription } from "../action";

function Confirm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation().state;
  const { drafted_claim_id } = useSelector(
    (state: ReducersModal) => state.createDoctorClaimSecondStepReducer
  );
  const { id } = useSelector(
    (state: ReducersModal) => state.claimDetailReducer
  );
  console.log(drafted_claim_id, "drafted_claim_id", "id", id, location);
  const draftClaimId = location.editDoctor ? id : drafted_claim_id;
  const uploadPrescriptionAndClaim = (type: number) => {
    console.log(draftClaimId, "claim id");
    if (draftClaimId) {
      let params;
      if (type === 2) {
        params = {
          claim_id: draftClaimId,
          submit_type: type,
          // prescription_arr: [],
          // claim_arr: [],
          type_id: 2,
        };
      }
      if (type === 1) {
        params = {
          claim_id: draftClaimId,
          submit_type: type,
          // prescription_arr: prescriptionArr,
          // claim_arr: claimArr,
          type_id: null,
        };
      }
      dispatch(confirmPrescription(params, navigate, type));
    }
  };

  return (
    <div className="page-upload-prescription">
      <div className="bg-white mb-20">
        <Box>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            Template Claim ID: {draftClaimId ? draftClaimId : "N/A"}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{ fontWeight: "bold", fontSize: (theme) => theme.spacing(3.5) }}
          >
            This is a Doctor generated E-Claim so you can send it directly to
            insurance company without uploading any documents.
          </Typography>
        </Box>
      </div>
      <div className="bg-white mb-20">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography>Send This claim to insurance?</Typography>
          <Button
            onClick={() => uploadPrescriptionAndClaim(1)}
            variant="outlined"
            sx={{ mx: (theme) => theme.spacing(2) }}
          >
            Later
          </Button>
          <Button
            sx={[
              {
                color: "var(--white)",
              },
            ]}
            variant="contained"
            color="primary"
            onClick={() => uploadPrescriptionAndClaim(2)}
          >
            Now
          </Button>
        </Box>
      </div>
    </div>
  );
}

export default Confirm;
