import { useState } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Chip,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import { getCountry } from "../login/action";
import Schema from "../../schema";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../../components/formsUi/button/customButton";
// import TextField from "../../components/formsUi/textFiled";
import { useEffect } from "react";
import { ReducersModal } from "../../modal";
//@ts-ignore
import AddMedicineList from "./sentClaims/addMedicineList";
import HospitalSearch from "./search/hospitalSearch";
import FinalTextField from "./search/finalDiagnosis";
import DoctorSearch from "./search/doctorSearch";
import FormField from "../../schema/formField";
import Utils from "../../utils";
import {
  calculateTotalAmount,
  convertTranscribePrescriptionPayload,
} from "../../utils/commonFunctions";
import { addTranscribePrescriptions } from "./action";
import InstitutionHopitalClinic from "./pateintProfile/instituteHospitalClinic";
import DoctorDetails from "./pateintProfile/doctorDetails";
import AddMedicinePopUp from "./sentClaims/addMedicinePopUp";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

function TranscribePrescription() {
  const [open, setOpen] = useState(false);
  const [openDoctorDetail, setOpenDoctorDetail] = useState(false);
  const [openHospitalDetail, setOpenHospitalDetail] = useState(false);
  const [formikInitialValue, setFormikInitialValue] = useState<any>(
    FormField.transcribePrescription
  );
  const [doctorData, setDoctorData] = useState<any>({});
  const [hospitalData, setHospitalData] = useState<any>({});
  const [finalDiagnosis, setFinalDiagnosis] = useState<any>({});
  const [editAddMedicineData, setEditAddMedicineData] = useState<any>({});
  const dispatch = useDispatch();
  const { state }: any = useLocation();
  const { medicineList } = useSelector(
    (state: ReducersModal) => state.createMedicineDetailsListReducer
  );
  const { claimId, employeeNumber,bdl_options,benefitBalance } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );
  const { id,claimBenefitsDetails } = useSelector(
    (state: ReducersModal) => state.claimDetailReducer
  );
  const { benefit_balance,sub_benefit_balance } = claimBenefitsDetails
  const initialValuesFormik = useSelector(
    (state: ReducersModal) => state.addmidicineFormitModalReduce
  );
  const { onlyRead } = useSelector(
    (state: ReducersModal) =>
      state.createClaimStepTwoDoctorAndHospitalAndICDDataReducer
  );

  const { patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  //   let type='Outpatient';

  // if(card_type==3){
  // getBenefitLiaisonBalance(type,benefit)
  // }else{
  // searchBenefitWithPreAuthType(type,benefit)
  // }

  // let remainingBalance =  benefitRes[0]?.amount
  // console.log("benefit", searchBenefitWithPreAuthType("Outpatient", benefit));
  // console.log("benefit get", getBenefitLiaisonBalance("Outpatient", benefit));
  // console.log({ doctorData, hospitalData, formikInitialValue });

  const handleOpenDoctorDetail = () => {
    setOpenDoctorDetail(true);
  };
  const handleCloseDoctorDetail = () => {
    setOpenDoctorDetail(false);
  };
  const handleOpenHospitalDetail = () => {
    setOpenHospitalDetail(true);
  };
  const handleCloseHospitalDetail = () => {
    setOpenHospitalDetail(false);
  };
  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch]);

  useEffect(() => {
    if (finalDiagnosis?.length > 0) {
      dispatch({
        type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
        payload: {
          finalDiagnosisDetail: finalDiagnosis,
        },
      });
    }
  }, [finalDiagnosis]);

  useEffect(() => {
    if (doctorData?.id) {
      dispatch({
        type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
        payload: {
          doctorDetail: doctorData,
        },
      });

      if (onlyRead) {
        setFormikInitialValue({
          ...formikInitialValue,
          hosphone: hospitalData?.phone,
          docphone: `${doctorData?.phone_code}-${doctorData?.phone_number}`,
        });
      } else {
        setFormikInitialValue({
          ...formikInitialValue,
          docphone: `${doctorData?.phone_code}-${doctorData?.phone_number}`,
        });
      }
    }

    if (doctorData === null) {
      dispatch({
        type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
        payload: {
          doctorDetail: {},
        },
      });
      setFormikInitialValue({
        ...formikInitialValue,
        docphone: "",
      });
    }
  }, [doctorData?.id]);

  useEffect(() => {
    if (hospitalData?.id) {
      dispatch({
        type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
        payload: {
          hospitalDetail: hospitalData,
        },
      });
      if (onlyRead) {
        setFormikInitialValue({
          ...formikInitialValue,
          hosphone: hospitalData?.phone,
          docphone: `${doctorData?.phone_code}-${doctorData?.phone_number}`,
        });
      } else {
        setFormikInitialValue({
          ...formikInitialValue,
          hosphone: hospitalData?.phone,
        });
      }
    }

    if (hospitalData === null) {
      dispatch({
        type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
        payload: {
          hospitaDetail: {},
        },
      });
      setFormikInitialValue({
        ...formikInitialValue,
        hosphone: "",
      });
    }
  }, [hospitalData?.id]);

  const validateForm = (checked: boolean) => {
    if (!doctorData?.id) {
      Utils.showAlert(2, "Please add Doctor name");
      return false;
    }
    if (!checked) {
      if (!hospitalData?.id) {
        Utils.showAlert(2, "Please add Hospital name");
        return false;
      }
    }

    if (!finalDiagnosis || !(finalDiagnosis.length > 0)) {
      Utils.showAlert(2, "Please add Final Diagnosis");
      return false;
    }

    return true;
  };

  const handleClose = () => {
    dispatch({
      type: Utils.ActionName.ADD_MEDICINE_FORMIK,
      payload: FormField.addMedicine,
    });
    setOpen(false);
  };
  const handleOpen = () => {
    const JUBILEE = process.env.REACT_APP_JUBILEE
    const KQ = process.env.REACT_APP_KQ
    
    if (!finalDiagnosis || !(finalDiagnosis.length > 0)) {
      Utils.showAlert(2, "Please add Final Diagnosis");
      return false;
    }

    if((patientInsurance==JUBILEE || patientInsurance==KQ)){
        if(bdl_options.isPrescription==false){
          Utils.showAlert(2, "Sorry, patient policy has waiting period for Prescription. You can not send E-prescription for this claim.");
        }else{
          setOpen(true);    
        }
    }else{
      setOpen(true);
    }
    
  };

  const handleClearClose = () => {
    // dispatch({
    //   type: Utils.ActionName.ADD_MEDICINE_FORMIK,
    //   payload: FormField.addMedicine,
    // });
    handleClose();
  };

  const handleOpenAddMedicine = (editData: any) => {
    setEditAddMedicineData(editData);
    handleOpen();
  };
  const handleTranscribePrescription = (values: any) => {
         
    // if (onlyRead) {
    //   dispatch({
    //     type: Utils.ActionName.GET_PATIENT_DATA,
    //     payload: {
    //       activeStep: 2,
    //     },
    //   });
    //   return;
    // }
    const totalAmount = calculateTotalAmount(medicineList)
    const availableBalance = sub_benefit_balance ? sub_benefit_balance : benefit_balance
    console.log("totalAmount", totalAmount);
    console.log("availableBalance", availableBalance);
    
    if(totalAmount > availableBalance){
      Utils.showAlert(2, "Total amount is greater than benefit balance");
      return;
    }
    const validate = validateForm(values?.checked);
    const claim = state?.edit ? id : claimId;
    if (!claim) {
      Utils.showAlert(2, "Claim id not found");
      return;
    }
    if (validate) {
      const payload = convertTranscribePrescriptionPayload({
        medicineList,
        doctorData,
        hospitalData,
        finalDiagnosis,
        values,
        employeeNumber,
      });
      if (payload && claim) {
        
        dispatch(addTranscribePrescriptions(payload, claim));
      }
    }
  };

  return (
    <div className="page-transcribe-prescription">
      <Box className="bg-white mb-20">
        <Formik
          initialValues={formikInitialValue}
          validationSchema={Schema.TranscribePrescriptionSchema}
          onSubmit={handleTranscribePrescription}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid item xs={12} sm={6} md={12}>
                    <Box sx={{ display: "flex" }}>
                      {/* {onlyRead && doctorDetail?.id ? (
                        <TextField
                          name="doctor"
                          label="Prescribing Doctor's Name"
                          size="small"
                          value={doctorDetail?.text}
                          disabled
                          sx={{ mr: 1 }}
                        />
                      ) : ( */}
                      <DoctorSearch
                        name="doctor"
                        setDoctorData={setDoctorData}
                        label="Prescribing Doctor's Name"
                        size="small"
                      />
                      {/* )} */}

                      <Button
                        // disabled={onlyRead}
                        size="small"
                        variant="contained"
                        sx={{
                          color: "var(--white)",
                        }}
                        onClick={handleOpenDoctorDetail}
                      >
                        Add
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Grid item xs={12} sm={6} md={12}>
                    {/* {onlyRead && doctorDetail?.phone_number ? (
                      <TextField
                        name="docphone"
                        label="Doc Phone"
                        size="small"
                        value={`${doctorDetail?.phone_code}-${doctorDetail?.phone_number}`}
                        disabled
                      />
                    ) : ( */}
                    <TextField
                      disabled={!!doctorData?.phone_number}
                      name="docphone"
                      label={doctorData?.phone_code ? "":"Doc Phone"}
                      size="small"
                      autoComplete="off"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={doctorData?.phone_code && `+${doctorData?.phone_code}${doctorData?.phone_number}`}
                      // value={values.docphone && `+${values.docphone}`}
                      error={Boolean(
                        errors.docphone && touched.docphone && errors.docphone
                      )}
                      helperText={
                        errors.docphone && touched.docphone && errors.docphone
                      }
                    />
                    {/* )} */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid item xs={12} sm={6} md={12}>
                    <Box sx={{ display: "flex" }}>
                      {/* {onlyRead && hospitalDetail?.id ? (
                        <TextField
                          name="hospital"
                          label="Institution/Hospital/Clinic Name"
                          size="small"
                          value={hospitalDetail?.text}
                          disabled
                          sx={{ mr: 1 }}
                        />
                      ) : ( */}
                      <HospitalSearch
                        name="hospital"
                        label="Institution/Hospital/Clinic Name"
                        setHospitalData={setHospitalData}
                        size="small"
                        disabled={values.checked}
                      />
                      {/* )} */}

                      <Button
                        disabled={values.checked}
                        size="small"
                        variant="contained"
                        sx={{
                          color: "var(--white)",
                        }}
                        onClick={handleOpenHospitalDetail}
                      >
                        Add
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Grid item xs={12} sm={6} md={12}>
                    {/* {onlyRead && hospitalDetail?.phone ? (
                      <TextField
                        disabled
                        name="hosphone"
                        label="Hospital Phone"
                        size="small"
                        value={hospitalDetail?.phone}
                      />
                    ) : ( */}
                    <TextField
                      disabled={values.checked || !!hospitalData?.phone}
                      className="w-100"
                      name="hosphone"
                      label="Hospital Phone"
                      size="small"
                      autoComplete="off"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.hosphone && `+${values.hosphone}`}
                      error={Boolean(
                        errors.hosphone && touched.hosphone && errors.hosphone
                      )}
                      helperText={
                        errors.hosphone && touched.hosphone && errors.hosphone
                      }
                    />
                    {/* )} */}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div
                    className="bg-gray mt-20 mb-20"
                    style={{ maxWidth: "500px" }}
                  >
                    <h4 style={{ color: "red" }}>
                      * Vitamins, Minerals, Supplements, Nutritional Products,
                      Pregnancy Care Products, Baby Foods and Medical Devices
                      are not covered by the insurance policy. Seek
                      Pre-authorization for these medications by calling
                      0740869442 before dispensing the mentioned products.
                      {/* Final Diagnosis (ICD10) */}
                    </h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControlLabel
                    sx={{
                      mt: 2,
                    }}
                    control={
                      <Checkbox
                        // disabled={onlyRead}
                        name="checked"
                        checked={values.checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Private Practice"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={4} md={5}>
                  {/* {onlyRead && finalDiagnosisDetail?.length > 0 ? (
                    <TextField
                      disabled
                      name="finalDiagnosis"
                      label="Final Diagnosis (ICD10)"
                      size="small"
                    />
                  ) : ( */}
                  <FinalTextField
                    name="finalDiagnosis"
                    setFinalDiagnosis={setFinalDiagnosis}
                    label="Final Diagnosis (ICD10)"
                    size="small"
                  />
                  {/* )} */}

                  {/* {onlyRead &&
                    finalDiagnosisDetail?.length > 0 &&
                    finalDiagnosisDetail.map((data: any) => {
                      return (
                        <Box key={data.code} sx={{ my: 0.5 }}>
                          <Chip
                            sx={{ color: "var(--white)" }}
                            color="primary"
                            label={data.code_description}
                          />
                        </Box>
                      );
                    })} */}
                </Grid>
                <Grid item xs={12} sm={5} md={5} className="center">
                <div
                    className="bg-gray"
                  >
                    <h4 style={{ color: "red" }}>
                    Please add all ICD10 codes before adding medicines. Once you add any medicine, you can not change ICD10 codes. You have to delete all added medicines in order to change ICD10 code later on.
                    </h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={3} md={2} className="right">
                <h3 className="mt-10">
                    Total Amount: {calculateTotalAmount(medicineList)} KES{" "}
                  </h3>
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "end", py: 1.5 }}>
                <Button
                  // disabled={onlyRead}
                  size="small"
                  variant="contained"
                  sx={{
                    color: "var(--white)",
                    maxWidth: "180px",
                    minWidth: "80px",
                  }}
                  onClick={handleOpen}
                >
                  Add Medicines
                </Button>
              </Box>
              <Box>
                <AddMedicineList
                  medicineList={medicineList}
                  handleOpenAddMedicine={handleOpenAddMedicine}
                />
              </Box>

              <div style={{ marginTop: 30 }} className="justify-end">
                <CustomButton
                  // isSubmitting={isSubmitting}
                  className="capitalize color-white"
                  sx={{ width: "auto" }}
                >
                  Proceed
                </CustomButton>
              </div>
            </Form>
          )}
        </Formik>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          // className={classes.customContentStyle}
        >
          <Box sx={{ display: "flex" }}>
            <DialogTitle
              sx={{
                padding: 0,
                marginTop: "10px",
                textAlign: "center",
                fontWeight: "bold",
                position: "relative",
                flexBasis: "100%",
              }}
              id="responsive-dialog-title"
            >
              {initialValuesFormik?.unqiueId ? "Edit" : "Add"} Medicines
              <Box
                sx={{
                  position: "absolute",
                  right: "20px",
                  top: "-3px",
                  width: "25px",
                  cursor: "pointer",
                }}
              >
                <IconButton
                  sx={{ p: 0 }}
                  color="primary"
                  aria-label="close"
                  onClick={handleClearClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
          </Box>
          <AddMedicinePopUp
            handleClose={handleClose}
            icd10Codes={finalDiagnosis}
          />
        </Dialog>
        <DoctorDetails
          open={openDoctorDetail}
          handleClose={handleCloseDoctorDetail}
        />
        <InstitutionHopitalClinic
          open={openHospitalDetail}
          handleClose={handleCloseHospitalDetail}
        />
      </Box>
    </div>
  );
}

export default TranscribePrescription;
