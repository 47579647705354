import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
  Button,
  Typography,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { batch, useDispatch, useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";
import Utils from "../../../utils";
import moment from "moment";
import {
  deleteTemplateClaims,
  getClaimDetailsListEditNormal,
  getTemplateClaims,
} from "../action";
import DeleteIcon from "@mui/icons-material/Delete";
// import SimpleDialogDemo from "./Dialogue";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { useNavigate } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "#fff";
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 450,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 2,
  px: 4,
  pb: 3,
};

function TableComponent() {
  const [deleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = (e: any, id: any) => {
    setDeleteId(id);
    // e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  // const handleClickOpen = () => {
  // setOpen(true);
  // };
  const { data, limit, count, page } = useSelector(
    (state: ReducersModal) => state.TemplateClaimsReducer
  );

  const handleChangePage = (value: any) => {
    // setPage(value + 1);
    dispatch({
      type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
      payload: {
        page: value + 1,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
      payload: {
        offset: page * limit - limit,
      },
    });
    dispatch(getTemplateClaims());
  }, [page]);

  const handleChangeRowsPerPage = (evt: any) => {
    dispatch({
      type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
      payload: {
        limit: +evt.target.value,
        offset: 1,
      },
    });

    dispatch(getTemplateClaims());
  };
  const handleDelete = () => {
    if (deleteId) {
      dispatch(deleteTemplateClaims(deleteId));
    }

    setOpen(!open);
  };
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Claim ID</TableCell>
              <TableCell>Claim Date</TableCell>
              <TableCell>Claim Type</TableCell>
              <TableCell>Patient Name</TableCell>
              <TableCell>Insurance Company</TableCell>
              <TableCell>Phone no </TableCell>
              <TableCell>Doctor</TableCell>
              <TableCell>Clinic</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((data: any) => {
                return (
                  <TableRow
                    key={data.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  // onClick={() => {
                  // if (data.id) {
                  // navigate(`${Utils.routes.claimDetail}/${data.id}`);
                  // }
                  // }}
                  >
                    <TableCell>
                      {data.id ? data.id : "N/A"}
                      {data?.auth_type_selected == 1 ? (
                        <sup className="fr-tag">FR</sup>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {/* {data.claim_date ? data.claim_date : 'N/A'} */}
                      {data.claim_date
                        ? moment(data.claim_date).format("DD/MM/YYYY")
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {data.claim_type ? data.claim_type : "N/A"}
                    </TableCell>
                    <TableCell>
                      {data?.beneficiary?.beneficiary_first_name &&
                        data?.beneficiary?.beneficiary_last_name
                        ? `${data?.beneficiary?.beneficiary_first_name} ${data?.beneficiary?.beneficiary_last_name}`
                        : "N/A"}
                      {/* {data?.user_name ? data?.user_name : "N/A"} */}
                    </TableCell>
                    <TableCell>
                      {data.insurance_name ? data.insurance_name : "N/A"}
                    </TableCell>
                    {/* <TableCell>{data.invoice_reference}</TableCell> */}
                    <TableCell>
                      {data?.beneficiary?.loginPhone
                        ? `+${data?.beneficiary?.loginPhone}`
                        : `+${data.user_phone}`}
                    </TableCell>
                    <TableCell>
                      {data.doctor_name ? data.doctor_name : "N/A"}
                    </TableCell>
                    <TableCell>
                      {data.hospital_name ? data.hospital_name : "N/A"}
                    </TableCell>
                    <TableCell>
                      {data.pharmacy_claim_amount
                        ? data.pharmacy_claim_amount
                        : "N/A"}
                    </TableCell>
                    {/* <TableCell>{data.pay_status}</TableCell> */}
                    <TableCell>
                      {Number(data?.status) === 1 && (
                        <Button
                          sx={{ minWidth: "unset" }}
                          onClick={() => {
                            // dispatch({
                            //   type: Utils.ActionName
                            //     .CLEAR_MEDICINE_DETAILS_LIST,
                            // });
                            batch(() => {
                              dispatch({
                                type: Utils.ActionName.GET_INSURANCE_COMPANY,
                                payload: {
                                  patientInsurance: data?.insurance_company_id,
                                },
                              });
                              dispatch({
                                type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
                                payload: {
                                  draftedClaimId: data?.id,
                                },
                              });
                              dispatch({
                                type: Utils.ActionName.CLAIM_DETAIL,
                                payload: {
                                  id: data?.id,
                                },
                              });

                            });
                            dispatch(getClaimDetailsListEditNormal(data?.id))
                              //@ts-ignore
                              .then((res: any) => {
                                if (data?.form_step) {
                                  if (Number(data?.type_id) === 5) {
                                    dispatch({
                                      type: Utils.ActionName.GET_PATIENT_DATA,
                                      payload: {
                                        activeStep: Number(data?.form_step),
                                      },
                                    });
                                    // navigate(Utils.routes.createClaim, {
                                    //   state: { edit: true },
                                    // });
                                    navigate(
                                      `${Utils.routes.editClaim}/${data?.id}`,
                                      {
                                        state: { edit: true },
                                      }
                                    );
                                  } else if (Number(data?.type_id) === 2) {
                                    dispatch({
                                      type: Utils.ActionName
                                        .CREATE_CLAIM_PRESCRIPTION,
                                      payload: {
                                        activeStep: Number(data?.form_step),
                                      },
                                    });
                                    navigate(
                                      `${Utils.routes.createClaimPrescription}/${data?.id}`,
                                      {
                                        state: { editDoctor: true },
                                      }
                                    );
                                  } else {
                                    Utils.showAlert(2, "Type id is not found");
                                  }
                                } else {
                                  Utils.showAlert(2, "Form step is not found");
                                }
                              })
                              .catch((err: any) => {
                                Utils.showAlert(2, err);
                              });
                          }}
                        >
                          <EditIcon color="secondary" />
                        </Button>
                      )}

                      <Button
                        sx={{ minWidth: "unset" }}
                        onClick={() => {
                          if (data.id) {
                            navigate(`${Utils.routes.claimDetail}/${data.id}`);
                          }
                        }}
                      >
                        <PreviewIcon color="info" />
                      </Button>
                      {(data?.type_id && Number(data?.type_id)) === 2 ? (
                        ""
                      ) : (
                        <Button
                          sx={{ minWidth: "unset" }}
                          onClick={(e) => {
                            handleOpen(e, data?.id);
                          }}
                        >
                          <DeleteIcon color="error" />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {" "}
                <TableCell>{"No Claims Found"}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length > 0 && (
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={page - 1}
            onPageChange={(e, value): any => handleChangePage(value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Rows:</span>}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                fontWeight: "bold",
                color: "primary",
              },
            }}
          />
        </TableRow>
      )}
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete?
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 6, ml: 19 }}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 6, ml: 4, color: "#fff" }}
            onClick={() => handleDelete()}
          >
            Yes
          </Button>
        </Box>
      </StyledModal>
    </>
  );
}

export default TableComponent;
