import Utils from "../../utils";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import { ifStringThenTrim } from "../../utils/commonFunctions";

export const getMyPersonalProfileInfo = () => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    let params = "";
    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.user,
      params,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.MY_PROFILE_PERSONAL_INFO,
          payload: {
            ...data,
          },
        });
        dispatch(globalLoaderFalse());

        localStorage.setItem("frUniqueId", respData?.data?.fr_unique_id);
        localStorage.setItem("roleId", data?.is_branch_pharmacy);
      },
      (error: any) => {
        dispatch(globalLoaderFalse());
        let { data } = error;
        Utils.showAlert(2, data?.message);
      }
    );
  };
};

export const getEditMyPersonalProfileInfo = (value: any) => {
  return (dispatch: Function) => {
    const {
      physical_address,
      pharmacyAdminName,
      id_number,
      profilePic,
      emailId,
      latitude,
      licensePharmacy,
      longitude,
      surname,
      businessPermit,
      pharmacyName,
      phoneNumber,
      pinCertificate,
      practiceCertificate,
      phoneCode,
      countryCode,
      crDocument,
      taxCompliance,
      businessPermitExpiry,
      practiceCertificateExpiry,
      licenseOfPharmacyExpiry,
      taxComplianceExpiry,
      searchPharmacyCity,
    } = value;
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    // ;

    const editMyPersonalProfileData = {
      physical_address: ifStringThenTrim(physical_address),
      admin_name: ifStringThenTrim(pharmacyAdminName),
      id_number: ifStringThenTrim(id_number),
      avatar: ifStringThenTrim(profilePic),
      country_code: ifStringThenTrim(countryCode),
      email: ifStringThenTrim(emailId),
      latitude: ifStringThenTrim(latitude),
      license_of_pharmacy: ifStringThenTrim(licensePharmacy),
      longitude: ifStringThenTrim(longitude),
      name_prefix: ifStringThenTrim(surname),
      business_permit: ifStringThenTrim(businessPermit),
      pharmacy_name: ifStringThenTrim(pharmacyName),
      phone_code: ifStringThenTrim(phoneCode),
      phone_number: ifStringThenTrim(phoneNumber),
      pin_certificate: ifStringThenTrim(pinCertificate),
      practice_certificate: ifStringThenTrim(practiceCertificate),
      cr_12: ifStringThenTrim(crDocument),
      tax_compliance: ifStringThenTrim(taxCompliance),
      business_permit_expiry: ifStringThenTrim(businessPermitExpiry),
      practice_certificate_expiry: ifStringThenTrim(practiceCertificateExpiry),
      license_of_pharmacy_expiry: ifStringThenTrim(licenseOfPharmacyExpiry),
      tax_compliance_expiry: ifStringThenTrim(taxComplianceExpiry),
      city_id: ifStringThenTrim(searchPharmacyCity),
      user_role: 3,
    };

    dispatch(globalLoaderTrue());
    const params = { ...editMyPersonalProfileData };
    Utils.constants.getAccessToken();
    Utils.api.patchApiCall(
      Utils.endPoints.user,
      params,
      (respData: any) => {
        const { status } = respData;
        // console.log("selectdata", respData,"PharmacyCity",searchPharmacyCity);
        if (status === Utils.constants.api_success_code.success) {
          Utils.api.putApiCall(
            Utils.endPoints.city,
            { city_id: searchPharmacyCity },
            (resp: any) => {
              let { data } = resp;
              // console.log(resp, "set city");
              if (status === Utils.constants.api_success_code.success) {
                dispatch({
                  type: Utils.ActionName.EDIT_MY_PROFILE_PERSONAL_INFO,
                  payload: {
                    ...data,
                  },
                });
                dispatch(globalLoaderFalse());
                Utils.showAlert(1, "Profile updated Successfully");

                localStorage.setItem("roleId", data?.is_branch_pharmacy);
              }
            },
            (error: any) => {
              let { data } = error;
              // console.log("selectdataerror", error);
              Utils.showAlert(2, data?.messages[0]);
              dispatch(globalLoaderFalse());
            }
          );
        }

        localStorage.setItem("frUniqueId", respData?.data?.fr_unique_id);
      },
      (error: any) => {
        dispatch(globalLoaderFalse());
        let { data } = error;
        Utils.showAlert(2, data?.message);
      }
    );
  };
};

export const getWorkTime = (id: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    let params = "";
    Utils.api.getApiCall(
      Utils.endPoints.workingHours + `/${id}`,
      params,
      (respData: any) => {
        const { data } = respData;
        dispatch({
          type: Utils.ActionName.GET_WORK_TIME,
          payload: {
            ...data,
          },
        });
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const setEditWorkTime = (params: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.workingHours,
      params,
      (respData: any) => {
        const { status } = respData;
        if (status === Utils.constants.api_success_code.success) {
          dispatch(globalLoaderFalse());
          Utils.showAlert(1, respData.data?.messages?.[0]);
        }
      },
      (error: any) => {
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const getPaymentDetails = (id: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    let params = "";
    Utils.api.getApiCall(
      Utils.endPoints.paymentMethod + `/${id}`,
      params,
      (respData: any) => {
        const { data } = respData;
        dispatch({
          type: Utils.ActionName.PAYMENT_DETAILS,
          payload: {
            ...data,
          },
        });
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export function addPaymentMethod(params: any) {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    Utils.api.putApiCall(
      Utils.endPoints.paymentMethod,
      params,
      (respData: any) => {
        if (respData.status === Utils.constants.api_success_code.success) {
          const { status } = respData;
          if (status === Utils.constants.api_success_code.success) {
            Utils.showAlert(1, respData.data?.messages?.[0]);
          }
        }
        dispatch(globalLoaderFalse());

        dispatch(getAllPaymentMethod());
      },
      (error: any) => {
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
}

export function deletePaymentMethod(payload: any) {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    Utils.constants.getAccessToken();
    Utils.api.deleteApiCallPayload(
      `${Utils.endPoints.paymentMethod}`,
      payload,
      (respData: any) => {
        const { status } = respData;
        if (status === Utils.constants.api_success_code.success) {
          dispatch(globalLoaderFalse());
          Utils.showAlert(1, respData.data?.messages?.[0]);
        }

        dispatch(getAllPaymentMethod());
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
}

export function getAllPaymentMethod() {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }

    dispatch({
      type: Utils.ActionName.PAYMENT_DETAILS,
      payload: {
        isGetPMApiLoading: true,
      },
    });
    Utils.constants.getAccessToken();
    let params = "";
    Utils.api.getApiCall(
      `${Utils.endPoints.paymentMethod}/1`,
      params,
      (respData: any) => {
        const { data } = respData;

        dispatch({
          type: Utils.ActionName.PAYMENT_DETAILS,
          payload: {
            ...data,
            isGetPMApiLoading: false,
          },
        });
      },
      (error: any) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.PAYMENT_DETAILS,
          payload: {
            isGetPMApiLoading: false,
          },
        });
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
}

export function resetAddPmAPIData() {
  return (dispatch: Function) => {
    // need to add correct reducer
    // dispatch({
    //   type: Utils.ActionName.PAYMENT_DETAILS,
    //   payload: {
    //   },
    // });
  };
}
