import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LocalImages } from "../../../utils/images";
// import Button from "../../../components/formsUi/reusableButton";
import Otp from "./OtpField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { onSubmitOtp, resendOtpPhone } from "../action";
import { ReducersModal } from "../../../modal";
import Timer from "../../../components/timer/index";
import { useTheme } from "@mui/material";
import Utils from "../../../utils";
const useStyles = makeStyles({
  leftScreen: {
    maxWidth: "400px",
    margin: "auto",
  },
  logo: {
    display: "flex",
    alignItems: "flex-start",
  },
  ResendBtn: ({ theme }: any) => ({
    fontSize: "14px",
    border: "none",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    color: "var(--bright-purple-mini)",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }),
  ResendBtnGrey: ({ theme }: any) => ({
    fontSize: "14px",
    backgroundColor: "#ffffff",

    border: "none",
    color: "#707070 !important",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }),
});

function OtpScreen() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { otp } = useSelector((state: ReducersModal) => state.otpReducer);
  const { userName } = useSelector((state: ReducersModal) => state.signInReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (otp.length === 4) {
      dispatch(onSubmitOtp(otp, navigate));
    } else {
      Utils.showAlert(2, "OTP required");
    }
  };

  const { enableResend, userStatus } = useSelector((state: ReducersModal) => state.signInReducer);

  const onResendOtp = () => {
    dispatch(resendOtpPhone(navigate));
  };

  const [timeText, setTimeText] = React.useState(0);

  const callbackFunction = (childData: any) => {
    setTimeText(childData);
  };

  return (
    <Box className={classes.leftScreen}>
      <Box className="mb-20">
        <img src={LocalImages.Logo} alt="logo" />
      </Box>
      <h2 className="color-blue mb-5">Verification Code</h2>
      <p className="color-text mb-20">
        Please enter the 4 digit OTP sent to{" "}
        {userStatus == "1" ? `the Pharmacy Admin ${userName}` : "your mobile number"}
        <Button onClick={() => navigate("/")} style={{ marginLeft: "-23px" }}>
          {" "}
          <EditIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
        </Button>
      </p>

      <Otp />

      <Button
        variant="contained"
        className="w-100 semi-bold capitalize color-white mt-20"
        //

        onClick={(event: any) => handleSubmit(event)}
      >
        Confirm
      </Button>
      <h5 className="color-text center mt-10 mb-20 thin">
        {/* Resend Code in 1:30 sec */}
        <button
          type="button"
          disabled={enableResend}
          className={timeText > 0 ? classes.ResendBtnGrey : classes.ResendBtn}
          onClick={() => onResendOtp()}
        >
          {"Resend Code "} {timeText > 0 ? "in" : ""} {timeText > 0 ? timeText : ""} {timeText > 0 ? "Sec" : ""}
        </button>
        <Timer parentCallback={callbackFunction} />
      </h5>

      <Link
        className="justify-start color-text"
        sx={{ fontSize: "14px", textDecoration: "none", cursor: "pointer" }}
        onClick={() => navigate("/")}
      >
        <ArrowBackIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
        &nbsp; Go Back
      </Link>
    </Box>
  );
}

export default OtpScreen;
