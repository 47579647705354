const endPoints = {
  logout: "admins/logout",
  getCountry: "countries",
  settings: "/settings",
  login: "auth",
  city: "city",
  user: "user",
  image: "/image",
  addMedicine: "/pharmacy-claim",
  workingHours: "/working-hours",
  insuranceCompany: "insurance-company",
  sentClaims: "/claim",
  searchInsuredMember: "search-insured-member",
  medicineList: "/medicine-list",
  searchHospital: "/hospital-info",
  searchDoctorHospital: "/doctor-info",
  search: "/search",
  uploadPrescription: "/upload-prescription",
  claimOtpVerify: "/claim-user-verification",
  refreshToken: "auth/1",
  createClaim: "/pharmacy-claim",
  claimMemberCardUpload: "/upload-user-photo",
  DoctorPrescriptions: "/chemist-order",
  HospitalPrescriptions:"/chemist-hospital-order",
  dashboard: "/dashboard",
  DrugFormulary: "/medicine-list",
  IsExport: "is_export",
  termsCondition: "/page/1",
  claimBalance: "chemist-order",
  chemistPayment: "chemist-payment",
  paymentMethod: "/chemist-payment",
  websitManagement: "/chemist-website-management",
  domainVerify: "/chemist-signup",
  orderStatus: "/order-status",
  bdlWaitingPeriod: "/bdl-waiting-period",
};

export default endPoints;
