import { Box, Button, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Schema from '../../../schema';
import EditProfileFields from './editProfileFields';
import {
  getEditMyPersonalProfileInfo,
  getMyPersonalProfileInfo,
} from '../action';
import Utils from '../../../utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backDiv: {
      display: 'flex',
      flexBasis: '15%',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        flexBasis: 'auto',
      },
    },
    arrowBackIcon: {
      color: theme.palette.secondary.main,
    },
    back: {
      '&.MuiTypography-root': {
        font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
          2
        )} Poppins, sans-serif`,
        color: theme.palette.secondary.main,
        paddingLeft: theme.spacing(0.5),
      },
    },
    stepperDiv: {
      flexBasis: '85%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: 'auto',
        marginTop: theme.spacing(1),
      },
    },
    nextDiv: {
      flexBasis: '15%',
    },
    nextBtn: {
      '&.MuiButton-root': {
        float: 'right',
        color: 'var(--white)',
        padding: theme.spacing(1, 2.5),
        font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
          1.5
        )} Poppins, sans-serif`,
      },
    },
    mainFormCard: {},
    modalStyle: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      backgroundColor: 'var(--white)',
      boxShadow: '24',
    },
  })
);

function EditProfile() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    avatar,
    email,
    pharmacy_name,
    city_name,
    physical_address,
    license_of_pharmacy,
    pin_certificate,
    business_permit,
    practice_certificate,
    admin_name,
    cr_12,
    tax_compliance,
    // name_prefix,
    // profilePic,
    longitude,
    latitude,
    phone_number,
    phone_code,
    country_code,
    name_prefix,
    business_permit_expiry,
    license_of_pharmacy_expiry,
    practice_certificate_expiry,
    tax_compliance_expiry,
    city_id
  } = useSelector((state: any) => state.MyProfilePersonalInfoReducer);

  const initialValues = {
    profilePic: '',
    pharmacyName: '',
    pharmacyAdminName: '',
    searchPharmacyCity: '',
    emailId: '',
    practiceCertificate: '',
    practiceCertificateExpiry: '',
    licensePharmacy: '',
    licenseOfPharmacyExpiry: '',
    businessPermit: '',
    businessPermitExpiry: '',
    pinCertificate: '',
    crDocument: '',
    taxCompliance: '',
    taxComplianceExpiry: '',
    physical_address: '',
    surname: '',
    latitude: '',
    longitude: '',
    phoneNumber: '',
    phoneCode: '',
    countryCode: '',
  };
  const [editValues, setEditValues] = useState(initialValues);

  useEffect(() => {
    dispatch(getMyPersonalProfileInfo());
  }, [dispatch]);

  useEffect(() => {
    setEditValues({
      profilePic: avatar,
      pharmacyName: pharmacy_name,
      surname: name_prefix,
      pharmacyAdminName: admin_name,
      searchPharmacyCity: city_id,
      emailId: email,
      practiceCertificate: practice_certificate,
      licensePharmacy: license_of_pharmacy,
      businessPermit: business_permit,
      pinCertificate: pin_certificate,
      crDocument: cr_12,
      taxCompliance: tax_compliance,
      physical_address: physical_address,
      latitude: latitude,
      longitude: longitude,
      phoneNumber: phone_number,
      phoneCode: phone_code,
      countryCode: country_code,
      businessPermitExpiry: business_permit_expiry,
      practiceCertificateExpiry: practice_certificate_expiry,
      licenseOfPharmacyExpiry: license_of_pharmacy_expiry,
      taxComplianceExpiry: tax_compliance_expiry,
    });
  }, [phone_number, tax_compliance_expiry]);

  const editProfileHandleSubmit = (value: any) => {
    // console.log('value', value);

    dispatch(getEditMyPersonalProfileInfo(value));
    dispatch({
      type: Utils.ActionName.MY_PROFILE_PERSONAL_INFO,
      payload: {
        email: '',
        pharmacy_name: '',
        city_name: '',
        physical_address: '',
        license_of_pharmacy: '',
        pin_certificate: '',
        business_permit: '',
        practice_certificate: '',
        admin_name: '',
        profilePic: '',
        longitude: '',
        latitude: '',
        phone_number: '',
        phone_code: '',
        country_code: '',
        name_prefix: '',
        cr_12: '',
        tax_compliance: '',
        business_permit_expiry: '',
        license_of_pharmacy_expiry: '',
        practice_certificate_expiry: '',
        tax_compliance_expiry: '',
      },
    });
    navigate(Utils.routes.myProfile);
    dispatch(getMyPersonalProfileInfo());
  };

  const EditProfile = () => {
    return (
      <div>
        <Formik
          initialValues={editValues}
          validationSchema={Schema.editProfileSchema}
          onSubmit={editProfileHandleSubmit}
          enableReinitialize
        >
          {() => {
            return (
              <Form>
                <EditProfileFields />
                <div className="justify-end bg-white mb-20">
                  <Box sx={{display:"flex", justifyContent:"space-between", width:"18%"}}>
                <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      color: 'var(--white)',
                      fontWeight: 'bold',
                    }}
                    onClick={()=>navigate(Utils.routes.myProfile)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      color: 'var(--white)',
                      fontWeight: 'bold',
                    }}
                  >
                    Submit
                  </Button>
                  </Box>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <div className="page-editmy-profile">
      <div className={classes.mainFormCard}>{EditProfile()}</div>
    </div>
  );
}

export default EditProfile;
