import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { createStyles, makeStyles } from "@mui/styles";
import ProfileData from "./profileData";
import WorkTime from "./workingData";
import PrintQrCode from "./printQrCode";
import MyBranches from "./myBranches";
import Payments from "./payments";
import { getRoleIdFromLS } from "../../utils/commonFunctions";
import PharmacyDevices from "./pharmacyDevices";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: "100%",
      typography: "body1",
    },
    tabLabel: {
      textTransform: "none",
      color: "black",
      "& .css-mgilup-MuiButtonBase-root-MuiTab-root.Mui-selected": {
        fontWeight: 550,
        backgroundColor: "red",
      },
    },
  })
);

function MyProfile() {
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="page-my-profile">
      <TabContext value={value}>
        {/* <TabList onChange={handleChange} className="bg-white p-0 mb-20">
          <Tab
            label={<Box className={classes.tabLabel}>Personal Info</Box>}
            value="1"
          />
          <Tab
            label={<Box className={classes.tabLabel}>Work Time</Box>}
            value="2"
          />
          <Tab
            label={<Box className={classes.tabLabel}>Print QR Code</Box>}
            value="3"
          />
        </TabList> */}

        {getRoleIdFromLS() === "0" ? (
          <TabList onChange={handleChange} className="bg-white p-0 mb-20">
            <Tab label="Personal Info" className={classes.tabLabel} value="1" />
            <Tab label="Work Time" value="2" />
            <Tab label="My Branches" value="3" />
            <Tab label="Payments" value="4" />
            <Tab label="Print QR Code" value="5" />
            <Tab label="Pharmacy Devices" value="6" />
          </TabList>
        ) : (
          <TabList onChange={handleChange} className="bg-white p-0 mb-20">
            <Tab label="Personal Info" className={classes.tabLabel} value="1" />
            <Tab label="Work Time" value="2" />
            <Tab label="Print QR Code" value="5" />
            <Tab label="Pharmacy Devices" value="6" />
          </TabList>
        )}
        <TabPanel value="1" sx={{ padding: "0px" }}>
          <ProfileData />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: "0px" }}>
          <WorkTime />
        </TabPanel>
        <TabPanel value="5" sx={{ padding: "0px" }}>
          <PrintQrCode />
        </TabPanel>
        <TabPanel value="6" sx={{ padding: "0px" }}>
          <PharmacyDevices />
        </TabPanel>
        {getRoleIdFromLS() === "0" && (
          <>
            <TabPanel value="3" sx={{ padding: "0px" }}>
              <MyBranches />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: "0px" }}>
              <Payments />
            </TabPanel>
          </>
        )}
      </TabContext>
    </div>
  );
}

export default MyProfile;
