import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import {
  Avatar,
  Grid,
  Theme,
  MenuItem,
  Radio,
  FormControlLabel,
  Typography,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
  Box,
  ThemeProvider,
  Checkbox,
  InputAdornment,
  Button,
  CardMedia,
  TextField
  // Select
} from "@mui/material";
import { Formik, Form } from "formik";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// import TextField from "../../components/formsUi/textFiled";
import Select from "../../components/formsUi/select/customSelect";
import RadioSelect from "../../components/formsUi/radio/RadioButton";
import CustomButtonWrapper from "../../components/formsUi/button/customButton";
import CustomModal from '../../components/modal/customModal';
import FR from '../../components/facialRecognition';
import { ReducersModal } from "../../modal";
import Utils from "../../utils";
import { policyCurrentStatus } from "../../utils/commonFunctions";
import { getInsuredMember, getInsuranceCompany, onSubmitMember } from "../myClaims/action";
import Schema from "../../schema";
import { globalLoaderTrue } from '../../reducer/rootReducer';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgressDiv: {
      textAlign: "center",
      "&.MuiAvatar-root": {
        width: "150px",
        margin: "auto",
      },
    },
    textFiledDiv: {
      width: "90%",
    },
    flexBox: {
      display: "flex",
      "& .MuiAvatar-root": {
        height: "25px",
        width: "20px",
      },
    },
    marginFlag: {
      marginLeft: "10px",
      textTransform: "uppercase",
      fontSize: "14px",
    },
    radioContainer: {
      "& .MuiFormControlLabel-root": {
        alignItems: "flex-start",
      },
    },
    test: {
      borderRadius: "12px",
      [theme.breakpoints.down("md")]: {
        margin: "unset",
        padding: "0 5px",
        width: "100%",
      },
      alignItems: "center !important",
      "& .MuiTypography-root": {
        width: "100%",
        fontSize: "14px",
      },
    },
    radioColor: {
      color: "#009bde !important",
    },
    errorMessage: {
      color: "red",
      opacity: "0.8",
      fontSize: "16px",
    },
    closeIcon: {
      position: "absolute",
      top: -10,
      right: -10,
      cursor: "pointer",
      width: "20px",
      height: "20px",
    },
    backdrop: ({ theme }: any) => ({
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    }),
  })
);

function RegisterFaceBiomteric() {
  const location:any = useLocation();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const [openPreview, setOpenPreview] = useState(false);
  const [showNoFaceIDPopup, setShowNoFaceIDPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [isStream, setIsStream] = useState("");
  const [proceedToRegister, setProceedToRegister] = useState(false);
  const { isLoading } = useSelector((state: ReducersModal) => state.globalLoaderReducer);
  const { data, patientInsurance } = useSelector((state: ReducersModal) => state.InsuranceCompanyReducer);
  const {
    patientCountryCode,
    cardListArray,
    // insuranceData,
    patientInsuranceMemberDataList,
    insuranceCardType,
    entityId,
    insurance_member_dependents,
    patientInsuranceMember,
    gender,
    patientName,
    patientDoB,
    phoneNumber,
    patientFamilyMemberData,
    search,
    showSearchMemberNo,
    familyMemberPhoneNumber,
    checked,
    principalPhoneNumber,
    insuranceMemberOptionSelect,
    age,
    isPrincipal,
    policyStatus,
    finalEntityId,
    userPrincipalId,
  } = useSelector((state: ReducersModal) => state.InsurancePatientProfileReducer);
  const [initialValue, setInitialValue] = useState({
    patientDob: patientDoB,
    gender: gender,
    patientName: patientName,
    phoneNumber: phoneNumber,
  });
  const insuranceData: any = data.find((a: any) => a.id === patientInsurance);
  console.log(entityId, "entityId", patientInsuranceMember)
  useEffect(() => {
    // dispatch(getCountry());
    dispatch(getInsuranceCompany());

    return () => {
      dispatch({
        type: Utils.ActionName.GET_INSURANCE_COMPANY,
        payload: {
          patientInsurance: ""
        }
      });
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          patientInsuranceMemberDataList: [],
          patientFamilyMemberData: [],
          entityId: "",
          insuranceMemberOptionSelect: false,
          phoneNumber: "",
          checked: false,
          insurance_member_dependents: [],
          familyMemberPhoneNumber: "",
          principalPhoneNumber: "",
          termsConditionChecked: false,
          userPrincipalId: "",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (policyStatus == 1 && entityId) {
      if (patientInsuranceMember[0]?.face_id === "" ||
        patientInsuranceMember[0]?.face_id === null ||
        patientInsuranceMember[0]?.face_id === undefined) {
        setShowNoFaceIDPopup(true);
      } else {
        setShowInfoPopup(true);
      }
    }
  }, [policyStatus, entityId, patientInsuranceMember[0]?.face_id])
  
  useEffect(()=>{
    if(location?.state?.fromClaim)setProceedToRegister(true)
  },[location])

  const searchMember = (beneficiary: any) => {
    let beneficiaryName = ''
    if (beneficiary?.card_type === "1") {
      beneficiaryName = beneficiary?.entity_id;
    } else if (beneficiary?.card_type === "2") {
      beneficiaryName = beneficiary?.employee_number;
    } else if (beneficiary?.card_type === "3") {
      beneficiaryName = beneficiary?.employee_number;
    } else {
      beneficiaryName = beneficiary?.entity_id;
    }
    return beneficiaryName;
  };

  const loader = () => {
    return (
      <Backdrop className={classes.backdrop} open={isLoading ? isLoading : false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const chooseCardType = (event: any) => {
    setProceedToRegister(false);
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        insuranceCardType: event.target.value,
        showSearchMemberNo: true,
        search: "",
        patientFamilyMemberData: [],
        patientInsuranceMember: [],
        insuranceMemberOptionSelect: false,
        patientInsuranceMemberDataList: [],
        checked: false,
        insurance_member_dependents: [],
        familyMemberPhoneNumber: "",
        termsConditionChecked: false,
        userPrincipalId: "",
        card1Added: false,
        card2Added: false,
        card3Added: false,
        card4Added: false,
        policyStatus: "",
        entityId: "",
        employeeNumber: "",
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    if (familyMemberPhoneNumber !== "" && familyMemberPhoneNumber !== null) {
      if (event.target.checked) {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: familyMemberPhoneNumber,
          },
        });
        setFieldValue("phoneNumber", familyMemberPhoneNumber);
        setInitialValue({
          ...initialValue,
          phoneNumber: familyMemberPhoneNumber,
        });
      } else {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: "",
          },
        });
        setFieldValue("phoneNumber", "");
        setInitialValue({
          ...initialValue,
          phoneNumber: "",
        });
      }
    } else if (principalPhoneNumber !== null && principalPhoneNumber !== undefined && principalPhoneNumber !== "") {
      if (event.target.checked) {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: principalPhoneNumber,
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: principalPhoneNumber,
        });
        setFieldValue("phoneNumber", familyMemberPhoneNumber);
      } else {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: "",
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: "",
        });
        setFieldValue("phoneNumber", familyMemberPhoneNumber);
      }
    }
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        checked: event.target.checked,
      },
    });
  };

  const chooseInsuranceMemberOptions = (event: any) => {
    const memberData: any = patientInsuranceMemberDataList.find((a: any) => a.entity_id === event.target.value);

    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        entityId: event.target.value,
        finalEntityId:
          insurance_member_dependents === null || insurance_member_dependents === undefined
            ? memberData.ent_dep_parent_on_policy
            : event.target.value,
        search:
          memberData.card_type === 1 || memberData.card_type === "1"
            ? memberData.entity_id
            : memberData.employee_number,
        insuranceMemberOptionSelect: false,
      },
    });
    dispatch(globalLoaderTrue());
    dispatch(onSubmitMember(memberData));
  };

  const insuranceMemberOptions = () => {
    return (
      <div style={{ display: "flex", width: "65%" }}>
        <Card sx={{ minWidth: 10 }}>
          <CardContent>
            <RadioSelect
              data={
                patientInsuranceMemberDataList.length > 0 &&
                patientInsuranceMemberDataList.map((item: any, index: any) => {
                  //
                  return (
                    <div key={index} className={classes.radioContainer}>
                      <FormControlLabel
                        value={item.entity_id}
                        className={classes.test}
                        control={<Radio className={`${classes.radioColor}`} />}
                        label={
                          <div style={{ display: "flex" }}>
                            <Typography component="div" variant="h6">
                              {/* {`${item.family_code} - ${item.employee_number}`} */}
                              {item.card_type === 1 || item.card_type === "1"
                                ? `${item.beneficiary_name}${" | "}${item.entity_id}`
                                : `${item.beneficiary_name}${" | "}${item.employee_number}`}
                            </Typography>
                          </div>
                        }
                      />
                    </div>
                  );
                })
              }
              label={"Insurance Member Options"}
              value={entityId}
              onSelect={(event: any) => chooseInsuranceMemberOptions(event)}
            />
          </CardContent>
        </Card>
      </div>
    );
  };

  const insuranceList = () => {
    return (
      <Formik
        initialValues={{ pharmacyName: "" }}
        onSubmit={() => { }}
      >
        {() => {
          return (
            <Form>
              <Select
                name="patientInsurance"
                label="Select Patient Insurance"
                size="small"
                value={patientInsurance}
                options={
                  data?.length === 0
                    ? ["No Insurance company available"].map((option: any, index: any) => {
                      return <MenuItem key={index}>{option}</MenuItem>;
                    })
                    : data.map((option: any, index: any) => {
                      return (
                        <MenuItem key={index} value={option.id}>
                          <div className={classes.flexBox}>
                            <Avatar src={`${Utils.constants.API_URL}${option.avatar}`} variant="square"></Avatar>

                            <div className={classes.marginFlag}>{option.name}</div>
                          </div>
                        </MenuItem>
                      );
                    })
                }
              />
            </Form>
          );
        }}
      </Formik>
    );
  };

  const PatientInfo = () => {
    return (
      <Grid container spacing={2} md={12}>
        {patientInsurance !== undefined && patientInsurance !== "" &&
          <Grid item sm={12} md={3}>
            <RadioSelect
              data={cardListArray.map((item: any, index: any) => {
                return (
                  <div key={index} className={classes.radioContainer}>
                    <FormControlLabel
                      value={`${item.id}`}
                      className={`w-100 ${classes.test}`}
                      control={<Radio className={`${classes.radioColor}`} />}
                      label={
                        <div className="space-between w-100 h-100">
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            {item.name}
                          </Typography>
                          <CardMedia
                            component="img"
                            sx={{
                              width: 70,
                              boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
                            }}
                            image={`${Utils.constants.API_URL}${item.image}`}
                            alt="Live from space album cover"
                          />
                        </div>
                      }
                    />
                  </div>
                );
              })}
              label={""}
              value={insuranceCardType}
              onSelect={(event: any) => chooseCardType(event)}
            />
          </Grid>
        }
        {showSearchMemberNo &&
          <Grid item sm={12} md={6}>
            <Formik
              enableReinitialize
              initialValues={{
                search: search,
              }}
              validationSchema={Schema.memberSearchSchema(insuranceCardType, insuranceData)}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values, setSubmitting)
                dispatch(getInsuredMember(patientInsurance, setSubmitting, values));
              }}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item sm={9} md={6}>
                      <TextField
                        sx={{ width: "100%" }}
                        name="search"
                        placeholder={
                          insuranceCardType === "1"
                            ? "Search with Smart Card"
                            : insuranceData !== undefined && insuranceData.id === "13"
                              ? "Search with LCT Member No."
                              : "Search with Member No."
                        }
                        label={
                          insuranceCardType === "1"
                            ? "Search with Smart Card"
                            : insuranceData !== undefined && insuranceData.id === "13"
                              ? "Search with LCT Member No."
                              : "Search with Member No."
                        }
                        size="small"
                        value={search}
                        onChange={(event: any) => {
                          setProceedToRegister(false);
                          dispatch({
                            type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                            payload: {
                              search: event.target.value,
                              patientFamilyMemberData: [],
                              patientInsuranceMember: [],
                              insuranceMemberOptionSelect: false,
                              patientInsuranceMemberDataList: [],
                              checked: false,
                              insurance_member_dependents: [],
                              familyMemberPhoneNumber: "",
                              termsConditionChecked: false,
                              userPrincipalId: "",
                              card1Added: false,
                              card2Added: false,
                              card3Added: false,
                              card4Added: false,
                              policyStatus: "",
                              entityId: "",
                              employeeNumber: "",
                            },
                          });
                        }}
                      />
                      {patientFamilyMemberData !== undefined &&
                        patientFamilyMemberData !== null &&
                        patientFamilyMemberData?.length > 0 ? (
                        <div
                          style={{
                            color: policyStatus === 1 ? "green" : "red",
                            marginTop: "5px",
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {policyStatus && (policyStatus === 1 ? <CheckIcon /> : <CloseIcon />)}
                          {policyCurrentStatus(policyStatus)}
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item sm={3} md={6}>
                      {" "}
                      <CustomButtonWrapper
                        variant="contained"
                        style={{ width: "100%" }}
                        sx={{
                          color: "var(--white)",
                          bgcolor: "var(--light-green)",
                        }}
                        type="submit"
                        isSubmitting={isSubmitting}
                      >
                        {isSubmitting ? "Searching..." : "Search"}
                      </CustomButtonWrapper>
                    </Grid>
                  </Grid>

                  {patientInsurance !== undefined &&
                    patientInsurance !== "" &&
                    patientInsuranceMemberDataList.length > 0 &&
                    !insuranceMemberOptionSelect
                    ? insuranceMemberOptions()
                    : ""}
                </Form>
              )}
            </Formik>
          </Grid>
        }
      </Grid>
    );
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {loader()}
        <div className="page-patient-profile">
          <div className="bg-white mb-20">

            <Grid container spacing={3} className="mb-30">
              <Grid item sm={12} md={3}>
                {insuranceList()}
              </Grid>
              {patientInsurance &&
                <Grid item sm={12} md={12}>
                  {PatientInfo()}
                </Grid>
              }
            </Grid>
            {insuranceMemberOptionSelect &&
              <Formik
                enableReinitialize
                initialValues={{ ...initialValue }}
                validationSchema={Schema.PatientInsuranceSchema(initialValue)}
                onSubmit={(values, { setSubmitting }) => {

                }}
              >
                {({ isSubmitting, setFieldValue, errors, touched }) => (
                  <Form>
                    <Grid container spacing={2} className="mb-20">
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography>Employer Name</Typography>
                        <TextField
                          fullWidth
                          name="employeename"
                          // label="Employee Name"
                          size="small"
                          // autoFocus={true}
                          disabled={true}
                          //@ts-ignore
                          value={
                            patientInsuranceMember !== undefined && patientInsuranceMember.length > 0
                              ? patientInsuranceMember[0].policy_holder_name
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography>Phone No.</Typography>
                        <TextField
                          type="tel"
                          className="w-100"
                          name="phoneNumber"
                          size="small"
                          placeholder={"Phone Number"}
                          disabled={
                            age < 15 ||
                              (patientInsuranceMember !== undefined &&
                                patientInsuranceMember !== null &&
                                patientInsuranceMember.length > 0 &&
                                patientInsuranceMember[0].phone !== null &&
                                patientInsuranceMember[0].phone !== "" &&
                                patientInsuranceMember[0].phone !== undefined) ||
                              checked
                              ? true
                              : false
                          }
                          onChange={(e: any) => {

                            dispatch({
                              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                              payload: { phoneNumber: e.target.value },
                            });
                          }}
                          value={phoneNumber}
                          inputProps={{
                            maxLength: 10,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                style={{
                                  background: "rgba(0, 0, 0, .1) !important",
                                }}
                                position="start"
                              >
                                +{patientCountryCode}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      {patientFamilyMemberData !== null &&
                        patientFamilyMemberData !== undefined &&
                        patientFamilyMemberData?.length > 0 &&
                        patientInsuranceMember !== undefined &&
                        patientInsuranceMember !== null &&
                        patientInsuranceMember.length > 0 &&
                        (patientInsuranceMember[0].phone === null ||
                          patientInsuranceMember[0].phone === "" ||
                          patientInsuranceMember[0].phone === undefined) ? (
                        <>
                          {insurance_member_dependents !== null &&
                            insurance_member_dependents !== undefined &&
                            insurance_member_dependents.length > 0 ? (
                            <Grid item xs={12} sm={12} md={6} lg = {4.5}>
                              {insurance_member_dependents !== undefined &&
                                insurance_member_dependents !== null &&
                                insurance_member_dependents.length > 0 ? (
                                <Typography>{"Family Member Phone Number"}</Typography>
                              ) : !isPrincipal ? (
                                <Typography>{"Principal Member Phone Number"}</Typography>
                              ) : (
                                <div style={{ display: "none" }}></div>
                              )}
                              <Select
                                name="familyMemberPhoneNumber"
                                // label="Family Member phone number"
                                size="small"
                                value={userPrincipalId}
                                options={
                                  insurance_member_dependents !== null &&
                                  insurance_member_dependents !== undefined &&
                                  insurance_member_dependents.length > 0 &&
                                  insurance_member_dependents.map((option: any, index: any) => (
                                    <MenuItem
                                      key={index}
                                      value={option.user_id}
                                    // onClick={(event: any) =>
                                    //   handleMenuItem(event,option.login_phone)
                                    // }
                                    >
                                      <div className={classes.marginFlag}>
                                        {`${"+"}${option.login_phone} (${option.beneficiary_first_name} ${" "} ${option.beneficiary_last_name
                                          })`}
                                      </div>
                                    </MenuItem>
                                  ))
                                }
                              />
                              &nbsp;&nbsp;
                              {patientFamilyMemberData !== null &&
                                patientFamilyMemberData !== undefined &&
                                patientFamilyMemberData?.length > 0 &&
                                patientInsuranceMember !== undefined &&
                                patientInsuranceMember !== null &&
                                patientInsuranceMember?.length > 0 &&
                                (patientInsuranceMember[0]?.phone === null ||
                                  patientInsuranceMember[0]?.phone === "" ||
                                  patientInsuranceMember[0]?.phone === undefined) ? (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checked}
                                      onChange={(e: any) => handleChange(e, setFieldValue)}
                                    />
                                  }
                                  label={`${"Use "}${insurance_member_dependents !== null &&
                                    insurance_member_dependents !== undefined &&
                                    insurance_member_dependents.length > 0
                                    ? "Family Member"
                                    : "Principal Member"
                                    }${" phone no."}`}
                                />
                              ) : (
                                <div style={{ display: "none" }}></div>
                              )}
                            </Grid>
                          ) : (insurance_member_dependents === null || insurance_member_dependents === undefined) &&
                            !isPrincipal ? (
                            <Grid item xs={12} sm={12} md={6} lg = {4.5}>
                              {insurance_member_dependents !== undefined &&
                                insurance_member_dependents !== null &&
                                insurance_member_dependents.length > 0 ? (
                                <Typography>{"Family Member Phone Number"}</Typography>
                              ) : !isPrincipal ? (
                                <Typography>{"Principal Member Phone Number"}</Typography>
                              ) : (
                                <div style={{ display: "none" }}></div>
                              )}
                              <TextField
                                type="tel"
                                className="w-100"
                                name="principalPhoneNumber"
                                size="small"
                                placeholder={"Principal Member Phone Number"}
                                value={principalPhoneNumber}
                                inputProps={{
                                  maxLength: 10,
                                }}
                                onChange={(e) => {
                                  dispatch({
                                    type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                                    payload: {
                                      principalPhoneNumber: e.target.value,
                                    },
                                  });
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start" disablePointerEvents={true}>
                                      +{patientCountryCode}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              &nbsp;&nbsp;
                              {patientFamilyMemberData !== null &&
                                patientFamilyMemberData !== undefined &&
                                patientFamilyMemberData?.length > 0 &&
                                patientInsuranceMember !== undefined &&
                                patientInsuranceMember !== null &&
                                patientInsuranceMember?.length > 0 &&
                                (patientInsuranceMember[0]?.phone === null ||
                                  patientInsuranceMember[0]?.phone === "" ||
                                  patientInsuranceMember[0]?.phone === undefined) ? (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checked}
                                      onChange={(e: any) => handleChange(e, setFieldValue)}
                                    />
                                  }
                                  label={`${"Use "}${insurance_member_dependents !== null &&
                                    insurance_member_dependents !== undefined &&
                                    insurance_member_dependents.length > 0
                                    ? "Family Member"
                                    : "Principal Member"
                                    }${" phone no."}`}
                                />
                              ) : (
                                <div style={{ display: "none" }}></div>
                              )}
                            </Grid>
                          ) : (
                            <div style={{ display: "none" }}></div>
                          )}
                        </>
                      ) : (
                        <div style={{ display: "none" }}></div>
                      )}
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography>Patient Name</Typography>
                        <TextField
                          name="PatientName"
                          // label="Patient Name"
                          disabled={true}
                          size="small"
                          value={patientName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography>Gender</Typography>
                        <TextField
                          name="gender"
                          size="small"
                          placeholder={"gender"}
                          disabled={true}
                          value={gender}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography>Patient DOB</Typography>
                        <TextField
                          name="patientDob"
                          // label="Patient DOB"
                          disabled={true}
                          size="small"
                          value={patientDoB}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            }
          </div>

          {showNoFaceIDPopup && !location?.state?.fromClaim &&
            <CustomModal
              title={""}
              content={
                <Typography variant="body1" sx={{ fontSize: 19 }}>
                  This patient does not have face biometric data registered in our database.
                  Kindly proceed to register this patient before providing your services.
                  <br /><br />
                  <b>
                    Once the Face Biometric is linked to the patient card, it cannot be changed.
                    Please ask the patient if the card belongs to them.
                  </b>
                  <br /><br />
                  <b style={{ color: "red" }}>
                    It is fraudulent to link the Face Biometric of a person to whom
                    that card does not belong.
                  </b>
                </Typography>
              }
              open={showNoFaceIDPopup}
              // handleClose={() => setShowNoFaceIDPopup(false)}
              handleButtonClick={() => {
                setShowNoFaceIDPopup(false);
                setProceedToRegister(true);
              }}
            />
          }

          {showInfoPopup && entityId &&
            <CustomModal
              title={"Info"}
              content={
                <Typography variant="body1" sx={{ fontSize: 19 }}>
                  This patient already has face biometric data registered in our database.
                </Typography>
              }
              open={showInfoPopup}
              handleClose={() => setShowInfoPopup(false)}
              handleButtonClick={() => {
                setShowInfoPopup(false);
              }}
            />
          }

          {proceedToRegister &&
            <FR
              registerFaceID={1}
              entity_id={patientInsuranceMember[0]?.entity_id || entityId}
              face_id={patientInsuranceMember[0]?.face_id}
              insuranceStatus={policyStatus == 1}
              patientName={patientName}
              insuranceCompanyName={patientInsuranceMember[0]?.insurance_company_name}
              phone_no={phoneNumber}
              insurance_company_id={patientInsuranceMember[0]?.insurance_company_id}
              insuranceNumber={searchMember(patientInsuranceMember[0])}
              principle_entity_id={phoneNumber === familyMemberPhoneNumber ? finalEntityId : entityId}  //principle_entity_id
              setIsStream={setIsStream}
            />
          }

        </div>
      </ThemeProvider>
    </>
  )
}

export default RegisterFaceBiomteric;