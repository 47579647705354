import Utils from "../../utils";
import {
  AddMedicinesModal,
  GetInsuranceCompanyModel,
  SentClaimsModal,
  InsurancePatientProfile,
  GetPatientInsuranceCompanyModel,
  MedicineDetailsModal,
  CreateMedicineDetailsList,
  MyClaimSearchMedicineDoctorHospitalModal,
  AddmidicineFormitModal,
  CreateClaimSecondSetp,
  ClaimDetails,
  TemplateClaimsModal,
  SentClaimDetails,
  TabModal,
  CreateClaimStepTwoDoctorAndHospitalAndICDData,
} from "../../modal";

export const AddMedicinesReducer = (state: AddMedicinesModal = new AddMedicinesModal(), action: any) => {
  switch (action.type) {
    case Utils.ActionName.ADD_MEDICINE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const SentClaimsReducer = (state: SentClaimsModal = new SentClaimsModal(), action: any) => {
  switch (action.type) {
    case Utils.ActionName.SENT_CLAIMS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const TabModelReducer = (state: TabModal = new TabModal(), action: any) => {
  switch (action.type) {
    case Utils.ActionName.Tab_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const TemplateClaimsReducer = (state: TemplateClaimsModal = new TemplateClaimsModal(), action: any) => {
  switch (action.type) {
    case Utils.ActionName.GET_TEMPLATE_CLAIMS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const InsuranceCompanyReducer = (
  state: GetInsuranceCompanyModel = new GetInsuranceCompanyModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_INSURANCE_COMPANY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const InsurancePatientProfileReducer = (
  state: InsurancePatientProfile = new InsurancePatientProfile(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.INSURANCE_PATIENT_PROFILE:
      return { ...state, ...action.payload };
    case Utils.ActionName.RESET_INSURANCE_PATIENT_PROFILE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const PatientInsuranceCompanyReducer = (
  state: GetPatientInsuranceCompanyModel = new GetPatientInsuranceCompanyModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_PATIENT_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const medicineDetailsReducer = (state: MedicineDetailsModal = new MedicineDetailsModal(), action: any) => {
  switch (action.type) {
    case Utils.ActionName.MEDICINE_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const createMedicineDetailsListReducer = (
  state: CreateMedicineDetailsList = new CreateMedicineDetailsList(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.CREATE_MEDICINE_DETAILS_LIST:
      const updatedValue = state?.medicineList.filter((item) => item.unqiueId !== action.payload.unqiueId);
      // console.log("reducer", updatedValue, action.payload, state);
      return {
        ...state,
        medicineList: [...updatedValue, action.payload],
      };
    case Utils.ActionName.DELETE_MEDICINE_DETAILS_LIST:
      return {
        medicineList: action.payload,
      };
    case Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST:
      return {
        medicineList: [],
      };
    default:
      return state;
  }
};

export const addmidicineFormitModalReduce = (
  state: AddmidicineFormitModal = new AddmidicineFormitModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.ADD_MEDICINE_FORMIK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const MyClaimSearchMedicineDoctorHospitalReducer = (
  state: MyClaimSearchMedicineDoctorHospitalModal = new MyClaimSearchMedicineDoctorHospitalModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.MYCLAIM_SEARCH_MEDICINE_DOCTOR_HOSPITAL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const createClaimSecondStepReducer = (
  state: CreateClaimSecondSetp = new CreateClaimSecondSetp(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.CREATE_CLAIM_SECOND_SETP:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const claimDetailReducer = (state: ClaimDetails = new ClaimDetails(), action: any) => {
  switch (action.type) {
    case Utils.ActionName.CLAIM_DETAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const sentClaimReducer = (state: SentClaimDetails = new SentClaimDetails(), action: any) => {
  switch (action.type) {
    case Utils.ActionName.SENT_CLAIM_DETAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const createClaimStepTwoDoctorAndHospitalAndICDDataReducer = (
  state: CreateClaimStepTwoDoctorAndHospitalAndICDData = new CreateClaimStepTwoDoctorAndHospitalAndICDData(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
