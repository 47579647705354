import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  DialogActions,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../hooks/useDebounce";
import { ReducersModal } from "../../../modal";
import Schema from "../../../schema";
import FormField, {
  duration,
  food,
  formulation,
  frequency,
  repetitionCycleOptions,
  supplyfrequency,
} from "../../../schema/formField";
import Utils from "../../../utils";
import {
  calculationTotalFormulation,
  checkEditableField,
  countTotalQuantity,
  uid,
} from "../../../utils/commonFunctions";
import { getMedicineDetails } from "../action";

type AddMedicinePopUpProps = {
  handleClose: () => void;
  doctorPrescriptions?: boolean;
  icd10Codes?: any[];
};
const useStyles = makeStyles(() =>
  createStyles({
    dialogContainer: {
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "7px",
      "&.MuiGrid-root": {
        marginTop: "none !important",
      },
    },
  })
);

const quantityMeasuringCate = ["bottle", "kit", "pack"]

function AddMedicinePopUp({
  doctorPrescriptions,
  handleClose,
  icd10Codes,
}: AddMedicinePopUpProps) {
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  const [value, setValue] = useState<any>("");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [initialValue, setInitialValue] = useState<any>(FormField.addMedicine);
  const [calculatedFormulationState, setCalculatedFormulationState] =
    useState("");
  const [idontSee, setIdontSee] = useState(false);
  const debouncedSearchTerm: string = useDebounce<string>(
    inputValue && inputValue,
    500
  );
  const dispatch = useDispatch();
  const { price, quantity_per_bottle, quantity_measuring } = useSelector(
    (state: ReducersModal) => state.medicineDetailsReducer
  );

  const medicineDetails = useSelector(
    (state: ReducersModal) => state.medicineDetailsReducer
  );


  const { global_medicine_search } = useSelector((state: ReducersModal) => state.claimDetailReducer);

  // const loading = open && options.length === 0;
  const { patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  const initialValuesFormik = useSelector(
    (state: ReducersModal) => state.addmidicineFormitModalReduce
  );
  const { medicineList } = useSelector(
    (state: ReducersModal) => state.createMedicineDetailsListReducer
  );

  const { entityId, insuranceData } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );

  const handleIdontsee = (value: any) => {
    setValue("");
    setInputValue("")
    setIdontSee(value)
  }

  useEffect(() => {
    let actives = true;

    // if (!loading) {
    //   return undefined;
    // }

    if (debouncedSearchTerm === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (debouncedSearchTerm.length > 0) {
      let icd10CodeString = icd10Codes
        ? icd10Codes?.map(({ code }) => `${code}`).join(",")
        : "";
      const global_medicinesearch = idontSee ? 1 : 0;
      let params = `?chemist_web_data_type=${"getList"}&insurance_company_id=${patientInsurance}&search=${debouncedSearchTerm}&icd10_codes=${icd10CodeString}&entity_id=${entityId}${global_medicinesearch ? '&global_medicine_search=1' : ''}`;

      Utils.constants.getAccessToken();

      Utils.api.getApiCall(
        Utils.endPoints.medicineList,
        params,
        (response: any) => {
          if (response.status === 200) {
            const results: any = [...response?.data.body];

            if (actives) {
              let newOptions: any = [];
              let newResults: any = [];
              for (let ele of results) {
                newResults.push({
                  ...ele,
                  searchDrug: debouncedSearchTerm,
                });
              }
              if (results) {
                newOptions = [...newOptions, ...newResults];
              }
              //else {
              if (newOptions.length === 0 && global_medicine_search) {
                const newOP = [
                  {
                    "id": "0",
                    "name": debouncedSearchTerm,
                    "api": debouncedSearchTerm,
                    "quantity_measuring": "Tablet",
                    "Diagnosis": [],
                    "searchDrug": debouncedSearchTerm
                  }
                ]

                newOptions = [...newOptions, ...newOP]
              }
              // }

              if (newOptions.length > 1) {
                //newOptions = newOptions.filter((ele: any) => ele.id !== 0)
              }

              setOptions(newOptions);

            }
          }
        },
        (err: any) => {
          console.error(err);
        }
      );
    }

    return () => {
      actives = false;
    };
  }, [value, debouncedSearchTerm]);

  useEffect(() => {
    if (initialValuesFormik?.unqiueId) {
      setValue(initialValuesFormik.medicineName);
      setInitialValue({
        ...initialValue,
        ...initialValuesFormik,
      });
    }
  }, [initialValuesFormik?.unqiueId]);

  useEffect(() => {
    if (price) {
      setInitialValue({ ...initialValue, pricePerUnit: price });
    }
  }, [price]);

  useEffect(() => {
    if (initialValue.supplyfrequency === "4") {
      setInitialValue({ ...initialValue, repetitionCycle: "1" });
    }
  }, [initialValue.supplyfrequency]);

  useEffect(() => {
    if (
      initialValue.formulation &&
      initialValue.dosage &&
      initialValue.frequency &&
      initialValue.duration
    ) {
      const formulationUpdate = formulation.find(
        (item: any) => item.value === initialValue.formulation
      );
      if (formulationUpdate) {
        const totalQuantity = countTotalQuantity(
          formulationUpdate?.text,
          Number(initialValue.dosage) *
          Number(initialValue.frequency) *
          Number(initialValue.duration)
        );
        const calculatedFormulation = calculationTotalFormulation(
          formulationUpdate?.text
        );
        if (
          ((quantity_measuring?.toLowerCase() === "bottle" &&
            calculatedFormulation === "ml") || (quantityMeasuringCate.includes(quantity_measuring?.toLowerCase()))) && quantity_per_bottle &&
          quantity_per_bottle !== "0"
        ) {
          const noOfBottles =
            Number(totalQuantity) / Number(quantity_per_bottle);
          setInitialValue({
            ...initialValue,
            totalQuantity: String(Math.ceil(noOfBottles)),
            totalPrice: String(
              (Number(noOfBottles) * Number(initialValue.pricePerUnit)).toFixed(
                2
              )
            ),
          });
          setCalculatedFormulationState(
            `(${totalQuantity}${calculatedFormulation})`
          );

          console.log(
            "arrrr -->",
            quantity_measuring,
            calculatedFormulation,
            "noOfBottles ----->",
            noOfBottles,
            "totalQuantity -->",
            totalQuantity
          );
        } else {
          if (
            doctorPrescriptions &&
            initialValue.medicineName?.pharmacy_quantity
          ) {
            setCalculatedFormulationState(
              `(${initialValue.medicineName?.pharmacy_quantity}${calculatedFormulation})`
            );
          } else {
            if (calculatedFormulation === "ml") {
              setCalculatedFormulationState(
                `(${totalQuantity}${calculatedFormulation})`
              );
            } else {
              setCalculatedFormulationState(calculatedFormulation);
            }
          }
          setInitialValue({
            ...initialValue,
            totalQuantity: String(Math.ceil(totalQuantity)),
            totalPrice: String(
              (
                Number(totalQuantity) * Number(initialValue.pricePerUnit)
              ).toFixed(2)
            ),
          });
        }
      }
    }
  }, [
    initialValue.formulation,
    initialValue.dosage,
    initialValue.frequency,
    initialValue.duration,
    doctorPrescriptions,
  ]);

  //? only doctor prescription pass props then run
  useEffect(() => {
    if (initialValue.totalQuantity && doctorPrescriptions) {
      setInitialValue({
        ...initialValue,
        totalQuantity: String(Math.ceil(initialValue.totalQuantity)),
        totalPrice: String(
          (
            Number(initialValue.totalQuantity) *
            Number(initialValue.pricePerUnit)
          ).toFixed(2)
        ),
      });
    }
  }, [
    initialValue.totalQuantity,
    initialValue.pricePerUnit,
    doctorPrescriptions,
  ]);

  // *for onchange quantity and change total price
  useEffect(() => {
    if (
      !checkEditableField(initialValue.formulation) &&
      initialValue.totalQuantity
    ) {
      // console.log("initialValue", initialValue);
      setInitialValue({
        ...initialValue,
        totalPrice: String(
          (
            Number(initialValue.totalQuantity) *
            Number(initialValue.pricePerUnit)
          ).toFixed(2)
        ),
      });
    }
  }, [initialValue.totalQuantity]);

  const setformulation = (name: any,value:any) => {    
    
    console.log('value',value)
    console.log('name',name)

    if (
      name === "totalQuantity" &&
      doctorPrescriptions &&
      value &&
      initialValuesFormik?.unqiueId &&
      initialValuesFormik.totalQuantity &&
      Number(initialValuesFormik.totalQuantity) < Number(value)
    ) {
      console.log(
        "initialValue.totalQuantity-->",
        Number(value),
        "initialValuesFormik.totalQuantity ->>",
        Number(initialValuesFormik.totalQuantity),
        "check",
        Number(initialValuesFormik.totalQuantity) < Number(value)
      );
      return;
    }

    setInitialValue({
      ...initialValue,
      [name]: value.trim(),
    });
  };

  const handleChange = (evt: any) => {
    const { value, name } = evt.target;
 

    if (
      name === "totalQuantity" &&
      doctorPrescriptions &&
      value &&
      initialValuesFormik?.unqiueId &&
      initialValuesFormik.totalQuantity &&
      Number(initialValuesFormik.totalQuantity) < Number(value)
    ) {
      console.log(
        "initialValue.totalQuantity-->",
        Number(value),
        "initialValuesFormik.totalQuantity ->>",
        Number(initialValuesFormik.totalQuantity),
        "check",
        Number(initialValuesFormik.totalQuantity) < Number(value)
      );
      return;
    }

    setInitialValue({
      ...initialValue,
      [name]: value.trim(),
    });
  };

  useEffect(() => {
    if (doctorPrescriptions || initialValuesFormik?.unqiueId) return;
    if (initialValue?.medicineName?.name) {
      const medName = initialValue?.medicineName?.name.toLowerCase();
      const medMeasure = quantity_measuring?.toLowerCase();
      const formulationValue = formulation.reduce((res: string, item: any) => {
        if (
          medName?.includes(item.key.toLowerCase()) ||
          medMeasure?.includes(item.key.toLowerCase())
        )
          res = item.value;
        return res;
      }, "");
      setInitialValue({
        ...initialValue,
        formulation: formulationValue,
      });
    }
  }, [
    initialValue.medicineName,
    doctorPrescriptions,
    initialValuesFormik?.unqiueId,
  ]);

  const printMedicineNotes = (
    values: any,
    formulation: any,
    frequency: any,
    duration: any,
    supplyfrequency: any,
    repetitionCycleOptions: any
  ) => {
    let medName = values?.medicineName?.name ? values?.medicineName?.name : "";
    let formulationText = formulation.reduce((res: string, item: any) => {
      if (item.value == values.formulation) res = item.text;
      return res.toLowerCase();
    }, "");
    let frequencyText = frequency.reduce((res: string, item: any) => {
      if (item.value == values.frequency) res = item.text;
      return res.toLowerCase();
    }, "");
    let durationText = duration.reduce((res: string, item: any) => {
      if (item.value == values.duration) res = item.text;
      return res.toLowerCase();
    }, "");
    let supplyFreqText = supplyfrequency.reduce((res: string, item: any) => {
      if (item.value == values.supplyfrequency) res = item.text;
      return res.toLowerCase();
    }, "");
    let repetitionText = repetitionCycleOptions.reduce(
      (res: string, item: any) => {
        if (item.value == values.repetitionCycle) res = item.text;
        return res.toLowerCase();
      },
      ""
    );
    return `${medName} 
            ${values?.dosage && formulationText
        ? `${values?.dosage} ${values?.dosage == 1
          ? `${formulationText}`
          : `${formulationText}s`
        }`
        : ""
      } 
            ${frequencyText} 
            ${durationText ? "for " + durationText : ""}              
            ${supplyFreqText ? "to be supplied " + supplyFreqText : ""} 
            ${repetitionText && values.repetitionCycle != 1
        ? "(Repeat " + repetitionText + ")"
        : ""
      }`;
  };

  const onSubmit = (values: any, { setSubmitting }: any) => {
    const {
      dosage,
      duration,
      foodInstractions,
      formulation,
      frequency,
      medicineName,
      pricePerUnit,
      repetitionCycle,
      supplyfrequency,
      totalPrice,
      totalQuantity,
      unqiueId,
    } = values;
    const updatedValue = {
      medicineName,
      global_medicine_search: idontSee ? 1 : 0,
      dosage,
      pricePerUnit,
      repetitionCycle,
      supplyfrequency,
      totalPrice,
      totalQuantity,
      formulation,
      frequency,
      duration,
      foodInstractions,
      unqiueId: unqiueId || uid(),
    };
    const findMedicine = medicineList.filter(
      (med: any) => med?.medicineName?.id !== medicineName?.id
    );

    batch(() => {
      dispatch({
        type: Utils.ActionName.DELETE_MEDICINE_DETAILS_LIST,
        payload: findMedicine,
      });
      dispatch({
        type: Utils.ActionName.CREATE_MEDICINE_DETAILS_LIST,
        payload: updatedValue,
      });
      dispatch({
        type: Utils.ActionName.ADD_MEDICINE_FORMIK,
        payload: FormField.addMedicine,
      });
      dispatch({
        type: Utils.ActionName.MEDICINE_DETAILS,
        payload: { price: "", quantity_measuring: "", quantity_per_bottle: "" },
      });
    });
    setInitialValue(FormField.addMedicine);
    setCalculatedFormulationState("");
    setSubmitting(false);
    handleClose();
  };
  const filterOptions = createFilterOptions({
    stringify: (option: any) => `${option.name} ${option?.api} ${option?.searchDrug}`
  });
  console.log('medicine details',medicineDetails)
  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={
          doctorPrescriptions
            ? Schema.addMedicineDoctorPrescriptionSchema
            : Schema.AddMedicineSchema
        }
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <Box
                  sx={{ width: "500px", backgroundColor: idontSee ? "#d1ebed" : "" }}
                  className={classes.dialogContainer}
                >
                  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h5>Medicine Name</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <Autocomplete
                          value={value ? value : null}
                          size="small"
                          fullWidth
                          filterOptions={filterOptions}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id ||
                            option?.searchDrug === value?.searchDrug ||
                            option?.api === value?.api
                          }
                          onChange={(event: any, newValue: any) => {
                            setOptions(
                              newValue ? [newValue, ...options] : options
                            );

                            setValue(newValue);
                            if (newValue?.id) {
                              const global_medicine_search = idontSee ? 1 : 0;
                              dispatch(getMedicineDetails(newValue?.id, global_medicine_search));
                              if(medicineDetails?.formulation && medicineDetails?.formulation > 0){
                                setInitialValue({
                                  ...initialValue,
                                  ['formulation']: medicineDetails?.formulation && medicineDetails?.formulation > 0 ? medicineDetails?.formulation :''
                                });
                              }
                              setInitialValue({
                                ...initialValue,
                                medicineName: newValue,
                                // pricePerUnit: price || "",
                              });
                            }
                            if (newValue === null) {
                              if (initialValue?.unqiueId) {
                                setInitialValue({
                                  ...FormField.addMedicine,
                                  unqiueId: initialValue.unqiueId,
                                });
                              } else {
                                setInitialValue(FormField.addMedicine);
                              }
                              dispatch({
                                type: Utils.ActionName.MEDICINE_DETAILS,
                                payload: {
                                  price: "",
                                },
                              });
                            }
                          }}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          getOptionLabel={(option: any) => {
                            return `${option.name} ${option?.api?.trim() ? `(${option?.api})` : ""
                              }`;
                          }}
                          id="controllable-states-demo"
                          options={options}
                          renderOption={(props, option: any) => {
                            return (
                              option.id == 0 ? <> <Button onClick={() => handleIdontsee(true)}>I do not see the molecule prescribed. Click here..</Button> </> :
                                <>
                                  <li {...props} key={Math.random()}>
                                    <p>
                                      {option.name}{" "}
                                      <span
                                        style={{
                                          paddingLeft: "5px",
                                          font: "italic small-caps bold 16px/30px Georgia, serif",
                                          color: "green",
                                        }}
                                      >
                                        {option.api}
                                      </span>
                                    </p>
                                  </li>
                                </>
                            );
                          }}
                          disabled={
                            doctorPrescriptions ||
                            !!initialValuesFormik?.unqiueId
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="medicineName"
                              InputProps={{
                                ...params.InputProps,
                                // endAdornment: (
                                //   <>
                                //     {loading && inputValue.length >= 3 ? (
                                //       <CircularProgress
                                //         color="inherit"
                                //         size={20}
                                //       />
                                //     ) : null}
                                //     {params.InputProps.endAdornment}
                                //   </>
                                // ),
                              }}
                              error={Boolean(
                                errors.medicineName &&
                                touched.medicineName &&
                                errors.medicineName
                              )}
                              helperText={
                                errors.medicineName &&
                                touched.medicineName &&
                                errors.medicineName
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Formulation</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          fullWidth
                          onChange={handleChange}
                          disabled={medicineDetails?.is_reviewed && medicineDetails?.is_reviewed > 0 ? true : false}
                          onBlur={handleBlur}
                          value={medicineDetails?.formulation && medicineDetails?.formulation > 0 ? medicineDetails?.formulation : values.formulation}
                          name="formulation"
                          size="small"
                          error={Boolean(
                            errors.formulation &&
                            touched.formulation &&
                            errors.formulation
                          )}
                          helperText={
                            errors.formulation &&
                            touched.formulation &&
                            errors.formulation
                          }
                          select
                          InputProps={{
                            readOnly: doctorPrescriptions,
                          }}
                        >
                          {formulation.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Food Instructions</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.foodInstractions}
                          name="foodInstractions"
                          size="small"
                          error={Boolean(
                            errors.foodInstractions &&
                            touched.foodInstractions &&
                            errors.foodInstractions
                          )}
                          helperText={
                            errors.foodInstractions &&
                            touched.foodInstractions &&
                            errors.foodInstractions
                          }
                          InputProps={{
                            readOnly: doctorPrescriptions,
                          }}
                          select
                        >
                          {food.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Dosage</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          name="dosage"
                          size="small"
                          autoComplete="off"
                          fullWidth
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dosage}
                          error={Boolean(
                            errors.dosage && touched.dosage && errors.dosage
                          )}
                          helperText={
                            errors.dosage && touched.dosage && errors.dosage
                          }
                          InputProps={{
                            readOnly: doctorPrescriptions,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <p style={{ marginTop: "30px" }}>
                        {formulation.reduce((res: string, item: any) => {
                          if (item.value == values.formulation) res = item.text;
                          return res;
                        }, "")}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Frequency</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.frequency}
                          name="frequency"
                          size="small"
                          error={Boolean(
                            errors.frequency &&
                            touched.frequency &&
                            errors.frequency
                          )}
                          helperText={
                            errors.frequency &&
                            touched.frequency &&
                            errors.frequency
                          }
                          InputProps={{
                            readOnly: doctorPrescriptions,
                          }}
                          select
                        >
                          {frequency.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Duration</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.duration}
                          name="duration"
                          size="small"
                          error={Boolean(
                            errors.duration &&
                            touched.duration &&
                            errors.duration
                          )}
                          helperText={
                            errors.duration &&
                            touched.duration &&
                            errors.duration
                          }
                          InputProps={{
                            readOnly: doctorPrescriptions,
                          }}
                          select
                        >
                          {duration.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* {!doctorPrescriptions && ( */}
                  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Supplying frequency</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          InputProps={{
                            readOnly: doctorPrescriptions,
                          }}
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.supplyfrequency}
                          name="supplyfrequency"
                          size="small"
                          error={Boolean(
                            errors.supplyfrequency &&
                            touched.supplyfrequency &&
                            errors.supplyfrequency
                          )}
                          helperText={
                            errors.supplyfrequency &&
                            touched.supplyfrequency &&
                            errors.supplyfrequency
                          }
                          select
                        >
                          {supplyfrequency.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Repetition cycle</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.repetitionCycle}
                          name="repetitionCycle"
                          size="small"
                          error={Boolean(
                            errors.repetitionCycle &&
                            touched.repetitionCycle &&
                            errors.repetitionCycle
                          )}
                          helperText={
                            errors.repetitionCycle &&
                            touched.repetitionCycle &&
                            errors.repetitionCycle
                          }
                          inputProps={{
                            readOnly:
                              values.supplyfrequency === "4" ||
                                doctorPrescriptions
                                ? true
                                : false,
                          }}
                          select
                        >
                          {repetitionCycleOptions.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Total Quantity</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          sx={{ flexBasis: "48%" }}
                          name="totalQuantity"
                          size="small"
                          inputProps={{
                            readOnly: doctorPrescriptions
                              ? false
                              : checkEditableField(values.formulation),
                          }}
                          type="number"
                          autoComplete="off"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.totalQuantity}
                          error={Boolean(
                            errors.totalQuantity &&
                            touched.totalQuantity &&
                            errors.totalQuantity
                          )}
                          helperText={
                            errors.totalQuantity &&
                            touched.totalQuantity &&
                            errors.totalQuantity
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <p style={{ marginTop: "30px" }}>
                        {calculatedFormulationState
                          ? calculatedFormulationState
                          : ""}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Price per unit(in ksh)</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          name="pricePerUnit"
                          size="small"
                          inputProps={{
                            readOnly: true,
                          }}
                          autoComplete="off"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.pricePerUnit ? parseFloat(values.pricePerUnit).toFixed(2) : values.pricePerUnit}
                          error={Boolean(
                            errors.pricePerUnit &&
                            touched.pricePerUnit &&
                            errors.pricePerUnit
                          )}
                          helperText={
                            errors.pricePerUnit &&
                            touched.pricePerUnit &&
                            errors.pricePerUnit
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                      <h5>Total Price(in ksh)</h5>
                      <Grid item xs={12} sx={{ mt: 0.5 }}>
                        <TextField
                          name="totalPrice"
                          size="small"
                          inputProps={{ readOnly: true }}
                          autoComplete="off"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.totalPrice}
                          error={Boolean(
                            errors.totalPrice &&
                            touched.totalPrice &&
                            errors.totalPrice
                          )}
                          helperText={
                            errors.totalPrice &&
                            touched.totalPrice &&
                            errors.totalPrice
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
                    {printMedicineNotes(
                      values,
                      formulation,
                      frequency,
                      duration,
                      supplyfrequency,
                      repetitionCycleOptions
                    )}
                  </Grid>
                </Box>
              </Grid>
              <DialogActions sx={{ p: 2 }}>
                {doctorPrescriptions ? (
                  <Button
                    sx={{ fontWeight: "bold" }}
                    autoFocus
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    sx={{ fontWeight: "bold" }}
                    autoFocus
                    onClick={() => {
                      setCalculatedFormulationState("");
                      // dispatch({
                      //   type: Utils.ActionName.MEDICINE_DETAILS,
                      //   payload: { price: "" },
                      // });
                      if (initialValue?.unqiueId) {
                        setInitialValue({
                          ...FormField.addMedicine,
                          medicineName: initialValuesFormik?.medicineName,
                          pricePerUnit: initialValue.pricePerUnit,
                          unqiueId: initialValue.unqiueId,
                        });
                      } else {
                        // all clear
                        setValue("");
                        setInitialValue(FormField.addMedicine);
                      }
                    }}
                  >
                    Clear
                  </Button>
                )}

                <Button
                  type="submit"
                  sx={[
                    {
                      color: "var(--white)",
                    },
                  ]}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {initialValuesFormik?.unqiueId ? "Edit" : "Add"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default AddMedicinePopUp;

// teaspoon, tablespoon, drop, lotion application, gel application, Spray, mouth rinse, shampoo application, Ointment application, Cream application, Puff
// These formulation will be having an editable "Total quantity" fields
// Foe these formulations "teaspoon, tablespoon, drop" the total quantity will be calculated automatically if the Quantity per bottle defined in the Superadmin -> Catalog -> Medicine
