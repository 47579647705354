import { useState, useEffect, useRef } from "react";
import {
  Paper,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InputBase from "@mui/material/InputBase";
import { useSelector, useDispatch, batch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, createStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import "./../../../styles/table.css";
import "./../../../styles/listing-filters.css";
import { getTemplateClaims } from "../action";
import FilterComponent from "./filter";
import TableComponent from "./table";
import { useNavigate } from "react-router";
import Utils from "../../../utils";
import { ReducersModal } from "../../../modal";
import { getInsuranceCompany } from "../action";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { CSVLink } from "react-csv";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../../reducer/rootReducer";
import { yyyyMMddToDate } from "../../../utils/commonFunctions";

const useStyles = makeStyles(() =>
  createStyles({
    leftSideFilters: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: "10px",
      width: "44%",
    },
    rightSideFilters: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: "10px",
      marginLeft: "10px",
      width: "54.9%",
    },
    customContentStyle: {
      width: "100%",
      maxWidth: "none",
    },
    listingFilter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: "10px",
    },
    csv: {
      color: "var(----light-green)",
    },
  })
);

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "var(--white)";
  -webkit-tap-highlight-color: transparent;
`;
const style = {
  width: 450,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 2,
  px: 4,
  pb: 3,
};

const headers = [
  { label: "Claim ID", key: "id" },
  { label: "Claim Date", key: "brand_name" },
  { label: "Claim Type", key: "claim_type" },
  { label: "Patient Name", key: "patient_name" },
  { label: "Insurance Company", key: "insurance_name" },
  { label: "Phone no", key: "phone_no" },
  { label: "Doctor", key: "doctor_name" },
  { label: "Clinic", key: "hospital_name" },
  { label: "Amount", key: "pharmacy_claim_amount" },
];

function TemplateClaim() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [filter, setFiletr] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openExport, setOpenExport] = useState(false);
  const [fileData, setFileData] = useState<any>([]);
  const [fileName, setFileName] = useState<any>(".csv");
  const handleFilterClick = () => {
    setFiletr(!filter);
  };

  const debounceCount = useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearchChange = (event: any) => {
    dispatch({
      type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
      payload: {
        search: event.target.value,
        limit: 10,
        offset: 0,
        page: 1,
      },
    });
    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      dispatch(getTemplateClaims());
    });
  };
  const handleSearchEmpty = () => {
    dispatch({
      type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
      payload: {
        search: "",
      },
    });
    setSearchValue("");
    dispatch(getTemplateClaims());
  };
  const handleCreateClickOpen = () => {
    batch(() => {
      dispatch({
        type: Utils.ActionName.GET_PATIENT_DATA,
        payload: {
          activeStep: 0,
        },
      });
      dispatch({
        type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
        payload: {
          doctorDetail: {},
          hospitalDetail: {},
          finalDiagnosisDetail: [],
          onlyRead: false,
        },
      });
      dispatch({
        type: Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST,
      });
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          cardListArray: [],
          authType: [],
          insuranceData: [],
          showAuthenticationType: false,
          showOtpVerification: "",
          phoneNumber: "",
          search: "",
          insurance_member_dependents: [],
          patientInsuranceMember: [],
          patientInsuranceMemberDataList: [],
          insuranceMemberOptionSelect: false,
          patientFamilyMemberData: [],
          termsConditionChecked: false,
          showSearchMemberNo: false,
          claimId: "",
          benefits: [],
        },
      });
      dispatch({
        type: Utils.ActionName.GET_INSURANCE_COMPANY,
        payload: { patientInsurance: "" },
      });
    });
    navigate(Utils.routes.createClaim);
    // setOpen(true);
  };

  const toggleArrow = (filter: Boolean) => {
    return filter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  };
  useEffect(() => {
    // dispatch(getTemplateClaims());
    dispatch(getInsuranceCompany());
  }, [dispatch]);

  const { data, patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  const {
    limit,
    offset,
    hospital_name,
    status,
    search,
    insurance_company_id,
    claim_start_date,
    claim_end_date,
  } = useSelector((state: ReducersModal) => state.TemplateClaimsReducer);

  const fetchCsvData = async () => {
    let param = `?limit=${limit}&offset=${offset || 0}&hospital_name=${
      hospital_name || ""
    }&status=${status || 0}${
      search.length > 0 ? `&search=${search}` : ""
    }&insurance_company_id=${insurance_company_id || ""}&claim_start_date=${
      claim_start_date || ""
    }&claim_end_date=${
      claim_end_date || ""
    }&is_count_needed=${1}}&is_export=${true}`;

    dispatch(globalLoaderTrue());
    await Utils.constants.axios
      .get(Utils.endPoints.createClaim + param)
      .then(({ status, data }) => {
        if (!(status === 200 && data?.body?.length > 0)) return;
        let csvData: any = [];
        data?.body.map((elem: any) => {
          let rowData = {
            id: elem.id,
            brand_name: elem.claim_date ? elem.claim_date : "N/A",
            claim_type: elem.claim_type ? elem.claim_type : "N/A",
            patient_name:
              elem?.beneficiary?.beneficiary_first_name &&
              elem?.beneficiary?.beneficiary_last_name
                ? `${elem?.beneficiary?.beneficiary_first_name} ${elem?.beneficiary?.beneficiary_last_name}`
                : "N/A",
            insurance_name: elem.insurance_name ? elem.insurance_name : "N/A",
            phone_no: elem?.beneficiary?.loginPhone
              ? `+${elem?.beneficiary?.loginPhone}`
              : `+${elem?.user_phone}`,
            doctor_name: elem.doctor_name ? elem.doctor_name : "N/A",
            hospital_name: elem.hospital_name ? elem.hospital_name : "N/A",
            pharmacy_claim_amount: elem.pharmacy_claim_amount
              ? elem.pharmacy_claim_amount
              : "N/A",
          };
          csvData.push(rowData);
        });
        let filename = `Templateclaims-${yyyyMMddToDate()}.csv`;
        setFileName(filename);
        setFileData(csvData);
        dispatch(globalLoaderFalse());
      })
      .catch(() => {
        dispatch(globalLoaderFalse());
      });
  };

  const handleOpenExport = () => {
    fetchCsvData();
    setOpenExport(true);
  };

  const handleExportDownload = () => {
    fetchCsvData();
    setOpenExport(false);
  };

  const handleCloseExport = () => {
    setOpenExport(false);
  };

  return (
    <>
      <div className="page-template-claims">
        <div className="listing-filter">
          <div className={classes.leftSideFilters}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 500,
                boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Claim id / Patient Name / Doctor / Phone no / Clinic"
                onChange={(e: any) => {
                  handleSearchChange(e);
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
              />
              {searchValue.length > 0 ? (
                <Button>
                  <ClearIcon onClick={handleSearchEmpty} />
                </Button>
              ) : (
                ""
              )}
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div
            // className={classes.rightSideFilters}
            className="right-side-filters"
          >
            <Button
              size="large"
              variant="contained"
              sx={{
                color: "var(--white)",
                maxWidth: "180px",
                minWidth: "80px",
              }}
              onClick={handleOpenExport}
            >
              Export
            </Button>
            <div className="dropdown-cover">
              <Button
                onClick={handleFilterClick}
                variant="outlined"
                size="large"
                sx={{
                  maxWidth: "180px",
                  minWidth: "80px",
                  bgcolor: "var(--white)",
                }}
              >
                <FilterListIcon />
                &nbsp;
                <span>Filters</span>&nbsp;
                {toggleArrow(filter)}
              </Button>
            </div>
            <Button
              size="large"
              variant="contained"
              sx={{
                color: "var(--white)",
                maxWidth: "180px",
                minWidth: "80px",
              }}
              onClick={handleCreateClickOpen}
            >
              Create E-Claim
            </Button>
          </div>
        </div>
        {filter ? (
          <FilterComponent data={data} patientInsurance={patientInsurance} />
        ) : (
          " "
        )}
        <div className="table-cover">
          <TableComponent />
        </div>
      </div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openExport}
        onClose={handleCloseExport}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to Export?
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 6, ml: 19 }}
            onClick={handleCloseExport}
          >
            No
          </Button>
          {fileData.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 6, ml: 4, color: "var(--white)" }}
              onClick={handleExportDownload}
            >
              <CSVLink
                data={fileData}
                headers={headers}
                filename={fileName}
                className={classes.csv}
                asyncOnClick={true}
              >
                Yes
              </CSVLink>
            </Button>
          ) : (
            <Box sx={{ mt: -3.5, ml: 30 }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </StyledModal>
    </>
  );
}

export default TemplateClaim;
