const routes = {
  signUp: "/signUp",
  otp: "/otp",
  dashboard: "/dashboard",
  myClaims: "/my-claims",
  doctorPrescriptions: "/doctor-prescriptions",
  hospitalPrescriptions:"/hospital-prescriptions",
  drugFormulary: "/drug-formulary",
  myProfile: "/my-profile",
  myProfileEdit: "/my-profile/edit",
  createClaim: "/create-claim",
  editClaim: "/edit-claim",
  claimDetail: "/claim-detail",
  sentClaimDetail: "/sent-claim-detail",
  createClaimPrescription: "/doctor-prescriptions/create-claim",
  createClaimPrescriptionForHospital: "/hospital-prescriptions/create-claim",
  termsAndCondition: "/terms-conditions",
  parentCompany: "/parent-company",
  reviewInsPay: "/review-payment",
  websiteManagement: "/website-management",
  exclusions: "/exclusions",
  // reviewInsPay:"/review-payment",
  registerFaceBiometric: "/register-face-biometric",
};

export default routes;
