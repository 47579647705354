import { useState, useEffect, useRef } from "react";
import {
  Paper,
  Button,
  Dialog,
  DialogTitle,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InputBase from "@mui/material/InputBase";
import { useSelector, useDispatch, batch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, createStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import "./../../../styles/table.css";
import "./../../../styles/listing-filters.css";
import {
  getInsuranceCompany,
  getInscurancePaymentClaims,
  getTotalAmount,
} from "../action";
import FilterComponent from "../sentClaims/filter";
import TableComponent from "../sentClaims/table";
// import PopUp from "./popUp";
import { useNavigate } from "react-router";
import Utils from "../../../utils";
import { ReducersModal } from "../../../modal";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { styled } from "@mui/system";
import { CSVLink } from "react-csv";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../../reducer/rootReducer";
import moment from "moment";

const useStyles = makeStyles(() =>
  createStyles({
    leftSideFilters: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: "10px",
      width: "44%",
    },
    rightSideFilters: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: "10px",
      marginLeft: "10px",
      width: "54.9%",
    },
    customContentStyle: {
      width: "100%",
      maxWidth: "none",
    },
    listingFilter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: "10px",
    },
    csv: {
      color: "var(----light-green)",
    },
  })
);
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = {
  width: 450,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 2,
  px: 4,
  pb: 3,
};
const headers = [
  { label: "Order ID", key: "claim_id" },
  { label: "Order Amount", key: "full_amount" },
  { label: "Pharmacy Name", key: "pharmacy_name" },
  { label: "Payment Status", key: "status_name" },
  { label: "Payment Method	", key: "pharmacy_payment_method" },
  { label: "Insurance Company", key: "insurance_company_name" },
  { label: "Transaction ID", key: "payment_done_id" },
  { label: "Date", key: "createDate" },
];
function SentClaims(value: any) {
  const now = new Date();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filter, setFiletr] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [fileData, setFileData] = useState<any>([]);
  const [fileName, setFileName] = useState<any>(".csv");
  const { pharmacy_branches, pharmacy_name } = useSelector(
    (state: ReducersModal) => state.MyProfilePersonalInfoReducer
  );
  const handleFilterClick = () => {
    setFiletr(!filter);
  };

  const debounceCount = useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearchEmpty = () => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PAYMENT,
      payload: {
        search: "",
      },
    });
    setSearchValue("");
    dispatch(getInscurancePaymentClaims());
  };

  const handleSearchChange = (event: any) => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PAYMENT,
      payload: {
        search: event.target.value,
        limit: 10,
        offset: 0,
        page: 1,
      },
    });
    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      dispatch(getInscurancePaymentClaims());
    });
    setSearchValue(event.target.value);
  };
  const handleCreateClickOpen = () => {
    batch(() => {
      dispatch({
        type: Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST,
      });
      dispatch({
        type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
        payload: {
          doctorDetail: {},
          hospitalDetail: {},
          finalDiagnosisDetail: [],
          onlyRead: false,
        },
      });

      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          showSearchMemberNo: false,
        },
      });
      dispatch({
        type: Utils.ActionName.GET_INSURANCE_COMPANY,
        payload: { patientInsurance: "" },
      });
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          cardListArray: [],
          authType: [],
          insuranceData: [],
          showAuthenticationType: false,
          showOtpVerification: "",
          phoneNumber: "",
          search: "",
          insurance_member_dependents: [],
          patientInsuranceMember: [],
          patientInsuranceMemberDataList: [],
          insuranceMemberOptionSelect: false,
          patientFamilyMemberData: [],
          termsConditionChecked: false,
          showSearchMemberNo: false,
          claimId: "",
          benefits: [],
        },
      });
      dispatch({
        type: Utils.ActionName.GET_PATIENT_DATA,
        payload: {
          activeStep: 0,
        },
      });
    });
    navigate(Utils.routes.createClaim);
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleArrow = (filter: Boolean) => {
    return filter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  };

  useEffect(() => {
    // dispatch(getSentClaims());
    dispatch(getInsuranceCompany());
    dispatch(getTotalAmount());
    if (
      localStorage.getItem("roleId") &&
      localStorage.getItem("roleId") === "1"
    ) {
      navigate(Utils.routes.dashboard);
    }
    return () => {
      dispatch({
        type: Utils.ActionName.INSURANCE_PAYMENT,
        payload: {
          search: "",
          limit: 10,
          offset: 0,
          page: 1,
          totalAmount: 0,
          data: [],
          isClaimCheck: [],
        },
      });
    };
  }, [dispatch]);

  const { data, patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  const {
    totalAmount,
    isClaimCheck,
    option,
    offset,
    limit,
    search,
    claim_start_date,
    claim_end_date,
    branch_pharmacy_id,
    status,
    data: dataPayment,
  } = useSelector((state: ReducersModal) => state.insurancePaymentReducer);
  const handleInitiate = () => {
    localStorage.setItem("claimId", isClaimCheck.join(","));
    localStorage.setItem("option", `${option}`);
    navigate(Utils.routes.reviewInsPay);
  };

  const handleOpen = () => {
    fetchData();
    setOpen(true);
  };
  const handleExportDownload = () => {
    fetchData();
    setOpen(false);
  };
  const fetchData = async () => {
    let param = `?limit=${limit}&offset=${
      offset || 0
    }&filter[statuses]=${status}${
      search.length > 0 ? `&search=${search}` : ""
    }&option=${option}&from_date=${claim_start_date || ""}&to_date=${
      claim_end_date || ""
    }&branch_pharmacy_id=${branch_pharmacy_id}&is_export=${true}`;
    dispatch(globalLoaderTrue());
    await Utils.constants.axios
      .get(Utils.endPoints.paymentMethod + param)
      .then((response) => {
        let csvData: any = [];
        response.data?.body.map((elem: any) => {
          let rowData = {
            claim_id: +option === 1 ? elem.claim_id : elem.order_id,
            full_amount: elem.full_amount ? elem.full_amount + " KES" : "N/A",
            pharmacy_name: elem?.pharmacy_name ? elem?.pharmacy_name : "N/A",
            status_name: elem.status_name ? elem.status_name : "N/A",
            pharmacy_payment_method: elem?.pharmacy_payment_method
              ? elem?.pharmacy_payment_method
              : "N/A",
            insurance_company_name: elem?.insurance_company_name
              ? elem?.insurance_company_name
              : "-",
            payment_done_id: elem?.payment_done_id
              ? elem?.payment_done_id
              : "N/A",
            createDate: elem?.createDate
              ? moment(elem.createDate).format("DD/MM/YYYY")
              : "-",
          };
          csvData.push(rowData);
        });

        setFileName(
          (
            pharmacy_name +
            "_regular_payments_" +
            moment(now).format("YYYY-MM-DD_hh-mm-ss")
          )
            .replace(" ", "_")
            .toLowerCase()
        );
        setFileData(csvData);
        dispatch(globalLoaderFalse());
      });
  };

  return (
    <div className="page-sent-claims bg-white">
      <div className="listing-filter">
        <div className={classes.leftSideFilters}>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 500,
              boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by Claim ID / Order ID"
              onChange={(e: any) => {
                handleSearchChange(e);
                setSearchValue(e.target.value);
              }}
              value={searchValue}
            />
            {searchValue.length > 0 ? (
              <Button>
                <ClearIcon onClick={handleSearchEmpty} />
              </Button>
            ) : (
              ""
            )}
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <StyledModal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
          onClose={handleClose}
          // BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to Export?
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              sx={{ mt: 6, ml: 19 }}
              onClick={handleClose}
            >
              No
            </Button>
            {fileData.length > 0 ? (
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 6, ml: 4, color: "var(--white)" }}
                onClick={handleExportDownload}
              >
                <CSVLink
                  data={fileData}
                  headers={headers}
                  filename={fileName}
                  className={classes.csv}
                  asyncOnClick={true}
                >
                  Yes
                </CSVLink>
              </Button>
            ) : (
              <Box sx={{ mt: -3.5, ml: 30 }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </StyledModal>
        <div
          // className={classes.rightSideFilters}
          className="right-side-filters"
        >
          <div className="dropdown-cover">
            <Button
              onClick={handleFilterClick}
              variant="outlined"
              size="large"
              sx={{
                maxWidth: "180px",
                minWidth: "80px",
                bgcolor: "var(--white)",
              }}
            >
              <FilterListIcon />
              &nbsp;
              <span>Filters</span>&nbsp;
              {toggleArrow(filter)}
            </Button>
          </div>
          <Button
            size="large"
            variant="contained"
            sx={{
              color: "var(--white)",
              maxWidth: "180px",
              minWidth: "80px",
            }}
            style={{ fontSize: 13 }}
            onClick={handleInitiate}
            disabled={!isClaimCheck.length}
          >
            Initiate Payment
          </Button>
          <Button
            onClick={handleOpen}
            variant="contained"
            size="large"
            sx={{
              maxWidth: "180px",
              minWidth: "80px",
              color: "var(--white)",
            }}
            disabled={!data.length || dataPayment.length === 0}
          >
            <span>Export</span>
          </Button>
          
        </div>
      </div>
      {filter ? (
        <FilterComponent
          data={data}
          patientInsurance={patientInsurance}
          branchData={pharmacy_branches}
        />
      ) : (
        " "
      )}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {" "}
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 300,
            boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
          }}
          style={{ margin: "10px 0px" }}
        >
          <h3 style={{ marginRight: "5px" }}> Current Balance: </h3>{" "}
          <b style={{ fontSize: 18 }}>
            {" "}
            {totalAmount ? parseFloat(totalAmount).toFixed(2) : 0} KES{" "}
          </b>
        </Paper>
      </div>
      <div className="table-cover">
        <TableComponent />
      </div>
    </div>
  );
}

export default SentClaims;
