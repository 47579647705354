import * as Yup from "yup";
//@ts-ignore
import mapValues from "lodash/mapValues";
/**
 * @description
 * @param t language
 */

const LoginSchema = (countryCodeLength: any) =>
  Yup.lazy((obj: {}) =>
    Yup.object(
      mapValues(obj, (value: any, key: any) => {
        if (key === "phoneNumber") {
          return Yup.string()
            .min(Number(countryCodeLength), `${"Minimum"} ${countryCodeLength} ${"digits"}`)
            .typeError("Please enter a valid phone number")
            .required("Please enter a phone number")
            .max(Number(countryCodeLength), `${"Minimum"} ${countryCodeLength} ${"digits"}`)
            .test(
              "test-compare a few values",
              "Phone number is Invalid. Please enter valid one",
              //@ts-ignore
              (value) => {
                if (value && value.length === 10) {
                  if (value?.charAt(0) != "0" || !"017".includes(value?.charAt(1))) {
                    return false;
                  } else return true;
                } else if (value && value.length === 9) {
                  if (!"17".includes(value?.charAt(0))) {
                    return false;
                  } else return true;
                } else return true;
              }
            );
        }
        if (key === "countryCode") {
          return Yup.string().required("Please select Country Code");
        }
      })
    )
  );

/**
 * @description
 * @param t language
 */
const PatientInsuranceSchema = (initialValue: any) =>
  Yup.lazy((obj: {}) =>
    Yup.object(
      mapValues(obj, (value: any, key: any) => {
        if (key === "patientInsurance") {
          return Yup.string().required("Please select Patient Insurance");
        }
        // if (key === "claimDate") {
        //   return Yup.string().required("Please select Claim Date");
        // }
        if (key === "phoneNumber") {
          return Yup.string()
            .min(Number(9), `${"Minimum"} ${9} ${"digits"}`)
            .typeError("Please enter a valid phone number")
            .required("Please enter a phone number")
            .max(Number(10), `${"Maximum"} ${10} ${"digits"}`)
            .test(
              "test-compare a few values",
              "Phone number is Invalid. Please enter valid one",
              // @ts-ignore
              (value) => {
                if (value && value.length === 10) {
                  if (
                    value?.charAt(0) != "0" ||
                    !"017".includes(value?.charAt(1))
                    // value?.charAt(1) != "0" ||
                    // (value?.charAt(1) != "7" ||
                    // value?.charAt(1) != "1"))
                  ) {
                    return false;
                  } else return true;
                } else if (value && value.length === 9) {
                  if (!"17".includes(value?.charAt(0))) {
                    return false;
                  } else return true;
                } else return true;
              }
            );
          //   0113255434 1000037-02
        }
        if (key === "principalPhoneNumber") {
          console.log(initialValue, "initialValue");
          return Yup.string()
            .min(Number(9), `${"Minimum"} ${9} ${"digits"}`)
            .typeError("Please enter a valid phone number")
            .max(Number(9), `${"Minimum"} ${9} ${"digits"}`)
            .test(
              "test-compare a few values",
              "Phone number is Invalid. Please enter valid one",
              //@ts-ignore
              (value) => {
                if (value && value.length === 10) {
                  if (value?.charAt(0) != "0" || !"017".includes(value?.charAt(1))) {
                    return false;
                  } else return true;
                } else if (value && value.length === 9) {
                  if (!"17".includes(value?.charAt(0))) {
                    return false;
                  } else return true;
                } else return true;
              }
            );
        }
        if (key === "termsConditionChecked") {
          return Yup.bool().oneOf([true], "Terms and Condition is required");
        }
      })
    )
  );

const fieldArrayFormSchema = () =>
  Yup.object().shape({
    organization: Yup.string().required("Organization is required"),
    users: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Name is Required"),
          email: Yup.string().required("email is Required").email("Email is Invalid"),
        })
      )
      .required("users are required")
      .max(3, "Max 3 users are required"),
  });

/**
 * @description
 * @param t language
 */
const ForgotSchema = () =>
  Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required"),
    recaptcha: Yup.string().required("Captcha is required"),
  });

/**
 * @description
 * @param t language
 */
const ResetPasswordSchema = () =>
  Yup.object().shape({
    newPassword: Yup.string().required("New password is required"),
    Password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords is not matching!"),
    recaptcha: Yup.string().required("Captcha is required"),
  });

const changePasswordSchema = () =>
  Yup.object().shape({
    password: Yup.string().min(8, "Must be greater then 8 characters").required("Password is required"),
    newPassword: Yup.string()
      .min(8, "Must be greater then 8 characters")
      .required("Please enter new password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

const setPasswordAdminSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(8, "Must be greater then 8 characters")
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

const CheckPasswordSchema = () =>
  Yup.object().shape({
    password: Yup.string().min(8, "Must be greater then 8 characters").required("Password is required"),
  });

const createProfileSchema = () =>
  Yup.object().shape({
    profilePic: Yup.string().required("Profile picture is required").nullable(),
    pharmacyName: Yup.string()
      .trim()
      .required("Name is required")
      .matches(/^[a-z0-9\s]+$/i, "Only alphanumeric are allowed for this field ")
      .min(3, "min 3 characters required")
      .max(100, "max 100 characters required")
      .required("Name is required")
      .nullable(),
    pharmacyAdminName: Yup.string()
      .trim()
      .required("Admin name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .min(3, "min 3 characters required")
      .max(100, "max 100 characters required")
      .required("Admin name is required")
      .nullable(),
    searchPharmacyCity: Yup.string().required("City is required").nullable(),
    emailId: Yup.string().trim().email().required("Email is required").nullable(),
    practiceCertificate: Yup.string().required("Certificate is required").nullable(),
    practice_certificate_expiry: Yup.string().required("Expiry date is required").nullable(),
    licensePharmacy: Yup.string().required("License is required").nullable(),
    domain: Yup.string().required("URL is required").nullable(),
    license_of_pharmacy_expiry: Yup.string().required("Expiry date is required").nullable(),
    businessPermit: Yup.string().required("Permit is required").nullable(),
    business_permit_expiry: Yup.string().required("Expiry date is required").nullable(),
    pinCertificate: Yup.string().required("PIN  Certificate is required").nullable(),
    crDocument: Yup.string().required("CR12 Document is required").nullable(),
    taxCompliance: Yup.string().required("Tax Compliance is required").nullable(),
    tax_compliance_expiry: Yup.string().required("Expiry date is required").nullable(),
    physical_address: Yup.string().trim().required("Physical address is Required").nullable(),
    // latitude: Yup.number().required("Latitutde is Required").nullable(),
    // longitude: Yup.number().required("Longitude is Required").nullable(),
  });
const editProfileSchema = () =>
  Yup.object().shape({
    profilePic: Yup.string().required("Profile picture is required").nullable(),
    pharmacyName: Yup.string()
      .trim()
      .required("Name is required")
      .matches(/^[a-z0-9\s]+$/i, "Only alphanumeric are allowed for this field ")
      .min(3, "min 3 characters required")
      .max(100, "max 100 characters required")
      .required("Name is required")
      .nullable(),
    pharmacyAdminName: Yup.string()
      .trim()
      .required("Admin name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .min(3, "min 3 characters required")
      .max(100, "max 100 characters required")
      .required("Admin name is required")
      .nullable(),
    searchPharmacyCity: Yup.string().required("City is required").nullable(),
    emailId: Yup.string().trim().email().required("Email is required").nullable(),
    practiceCertificate: Yup.string().required("Certificate is required").nullable(),
    practiceCertificateExpiry: Yup.string().required("Expiry date is required").nullable(),
    licensePharmacy: Yup.string().required("License is required").nullable(),
    licenseOfPharmacyExpiry: Yup.string().required("Expiry date is required").nullable(),
    businessPermit: Yup.string().required("Permit is required").nullable(),
    businessPermitExpiry: Yup.string().required("Expiry date is required").nullable(),
    pinCertificate: Yup.string().required("PIN  Certificate is required").nullable(),
    crDocument: Yup.string().required("CR12 Document is required").nullable(),
    taxCompliance: Yup.string().required("Tax Compliance is required").nullable(),
    taxComplianceExpiry: Yup.string().required("Expiry date is required").nullable(),
    physical_address: Yup.string().trim().required("Physical address is Required").nullable(),
    surname: Yup.string().trim().required("surname is Required").nullable(),
    latitude: Yup.string().trim().required("latitude is Required").nullable(),
    longitude: Yup.string().trim().required("longitude is Required").nullable(),
    phoneNumber: Yup.string().trim().required("Phone Number is Required").nullable(),
    phoneCode: Yup.string().trim().required("Phone Code is Required").nullable(),
    countryCode: Yup.string().trim().required("CountryCode is Required").nullable(),
    // latitude: Yup.number().required("Latitutde is Required").nullable(),
    // longitude: Yup.number().required("Longitude is Required").nullable(),
  });

const AddMedicineSchema = () =>
  Yup.object().shape({
    medicineName: Yup.object().required("Medicine Name is Required"),
    formulation: Yup.string().trim().required("Fomulation is Required"),
    dosage: Yup.string().trim().required("Dosage is Required"),
    frequency: Yup.string().trim().required("Frequency is Required"),
    duration: Yup.string().trim().required("Duration is Required"),
    foodInstractions: Yup.string().trim().required("Food Instruction is Required"),
    supplyfrequency: Yup.string().trim().required("Supply Frequency is Required"),
    repetitionCycle: Yup.string().trim().required("RepetitionCycle is Required"),
    totalQuantity: Yup.string().trim().required("Total Quantity is Required"),
    pricePerUnit: Yup.string().trim().required("Price PerUnit is Required"),
    totalPrice: Yup.string().trim().required("Total Price is Required"),
  });
const addMedicineDoctorPrescriptionSchema = () =>
  Yup.object().shape({
    medicineName: Yup.object().required("Medicine Name is Required"),
    formulation: Yup.string().trim().required("Fomulation is Required"),
    dosage: Yup.string().trim().required("Dosage is Required"),
    frequency: Yup.string().trim().required("Frequency is Required"),
    duration: Yup.string().trim().required("Duration is Required"),
    foodInstractions: Yup.string().trim().required("Food Instruction is Required"),
    // supplyfrequency: Yup.string()
    //   .trim()
    //   .required("Supply Frequency is Required"),
    // repetitionCycle: Yup.string()
    //   .trim()
    //   .required("RepetitionCycle is Required"),
    totalQuantity: Yup.string().trim().required("Total Quantity is Required"),
    pricePerUnit: Yup.string().trim().required("Price PerUnit is Required"),
    totalPrice: Yup.string().trim().required("Total Price is Required"),
  });

const selecteLocation = () =>
  Yup.object().shape({
    physical_address: Yup.string().required("Physical address is Required"),
    latitude: Yup.number().required("Latitutde is Required"),
    longitude: Yup.number().required("Longitude is Required"),
  });

const workTimeSchema = () =>
  Yup.object().shape({
    // mon
    workingSwitchNameMon: Yup.boolean().required("Required"),
    workingTimeNameToMon: Yup.date().required("Required"),
    workingTimeNameFromMon: Yup.date().required("Required"),
    // // .test("InvalidTime", "Invalid Time Format", (val: any) => {
    // //   console.log("val ", val);

    // //   const timeregex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
    // //   console.log("val tiemregex", val.match(timeregex));

    // //   return val.match(timeregex);
    // }),
    lunchSwitchNameMon: Yup.boolean().required("Required"),
    lunchTimeNameToMon: Yup.date().required("Required"),
    lunchTimeNameFromMon: Yup.date().required("Required"),
    // tue
    workingSwitchNameTue: Yup.boolean().required("Required"),
    workingTimeNameToTue: Yup.date().required("Required"),
    workingTimeNameFromTue: Yup.date().required("Required"),
    lunchSwitchNameTue: Yup.boolean().required("Required"),
    lunchTimeNameToTue: Yup.date().required("Required"),
    lunchTimeNameFromTue: Yup.date().required("Required"),
    // wed
    workingSwitchNameWed: Yup.boolean().required("Required"),
    workingTimeNameToWed: Yup.date().required("Required"),
    workingTimeNameFromWed: Yup.date().required("Required"),
    lunchSwitchNameWed: Yup.boolean().required("Required"),
    lunchTimeNameToWed: Yup.date().required("Required"),
    lunchTimeNameFromWed: Yup.date().required("Required"),
    // thu
    workingSwitchNameThu: Yup.boolean().required("Required"),
    workingTimeNameToThu: Yup.date().required("Required"),
    workingTimeNameFromThu: Yup.date().required("Required"),
    lunchSwitchNameThu: Yup.boolean().required("Required"),
    lunchTimeNameToThu: Yup.date().required("Required"),
    lunchTimeNameFromThu: Yup.date().required("Required"),
    // fri
    workingSwitchNameFri: Yup.boolean().required("Required"),
    workingTimeNameToFri: Yup.date().required("Required"),
    workingTimeNameFromFri: Yup.date().required("Required"),
    lunchSwitchNameFri: Yup.boolean().required("Required"),
    lunchTimeNameToFri: Yup.date().required("Required"),
    lunchTimeNameFromFri: Yup.date().required("Required"),
    // sat
    workingSwitchNameSat: Yup.boolean().required("Required"),
    workingTimeNameToSat: Yup.date().required("Required"),
    workingTimeNameFromSat: Yup.date().required("Required"),
    lunchSwitchNameSat: Yup.boolean().required("Required"),
    lunchTimeNameToSat: Yup.date().required("Required"),
    lunchTimeNameFromSat: Yup.date().required("Required"),
    // sun
    workingSwitchNameSun: Yup.boolean().required("Required"),
    workingTimeNameToSun: Yup.date().required("Required"),
    workingTimeNameFromSun: Yup.date().required("Required"),
    lunchSwitchNameSun: Yup.boolean().required("Required"),
    lunchTimeNameToSun: Yup.date().required("Required"),
    lunchTimeNameFromSun: Yup.date().required("Required"),
  });

const memberSearchSchema = (insuranceCardType: any, insuranceData: any) =>
  Yup.object().shape({
    search: Yup.string()
      .trim()
      .min(2, "Must be greater then 2 characters")
      .required(
        insuranceCardType === "1"
          ? "This field is required"
          : insuranceData !== undefined && insuranceData.name === "Liaison Insurance"
          ? "This field is required"
          : "This field is required"
      ),
  });

const TranscribePrescriptionSchema = () => {
  return Yup.object().shape({
    // doctor: Yup.string().required("Doctor is required"),
    // docphone: Yup.string().required("Doctor phone is required"),
    // hospital: Yup.object().required("hospital is required"),
    // hosphone: Yup.string().required("phone No is required"),
    // finalDiagnosis: Yup.object().required("finalDiagnosis No is required"),
    // checked: Yup.boolean().required("required")
  });
};
const TranscribeSchema = () => {
  return Yup.object().shape({
    doctor: Yup.string().trim().required("Please add Doctor name"),
    docphone: Yup.string().trim().required("Doctor Phone No. is required"),
    // hospital: Yup.string().trim().required("Please add Hospital name"),
    // hosphone: Yup.string().trim().required('Hospital Phone No. is required'),
    // finalDiagnosis: Yup.object().required("finalDiagnosis  is required"),
    checked: Yup.boolean().required("required"),
  });
};

const InstitutionHopitalClinicSchema = (countryCodeLength: any) =>
  Yup.lazy((obj: {}) =>
    Yup.object(
      mapValues(obj, (value: any, key: any) => {
        if (key === "phone_code") {
          return Yup.string().required("Phone code is required");
        }
        if (key === "phone_number") {
          return Yup.string()
            .min(Number(countryCodeLength), `${"Minimum"} ${countryCodeLength} ${"digits"}`)
            .typeError("Please enter a valid phone number")
            .required("Please enter a phone number")
            .max(Number(countryCodeLength), `${"Minimum"} ${countryCodeLength} ${"digits"}`);
        }
        if (key === "country") {
          return Yup.string().required("Country name required");
        }
        if (key === "city_id") {
          return Yup.string().required("City required");
        }

        if (key === "phone_number") {
          return Yup.string().required("Phone number is required");
        }
        if (key === "hospital_name") {
          return Yup.string().required("Name prefix is required");
        }
      })
    )
  );

const DoctorDetailsSchema = (countryCodeLength: any) =>
  Yup.lazy((obj: {}) =>
    Yup.object(
      mapValues(obj, (value: any, key: any) => {
        if (key === "phone_code") {
          return Yup.string().required("Phone code is required");
        }
        if (key === "phone_number") {
          return Yup.string()
            .min(Number(countryCodeLength), `${"Minimum"} ${countryCodeLength} ${"digits"}`)
            .typeError("Please enter a valid phone number")
            .required("Please enter a phone number")
            .max(Number(countryCodeLength), `${"Minimum"} ${countryCodeLength} ${"digits"}`);
        }
        if (key === "country") {
          return Yup.string().required("Country name required");
        }
        if (key === "city_id") {
          return Yup.string().required("City required");
        }

        if (key === "phone_number") {
          return Yup.string().required("Phone number is required");
        }
        if (key === "name_prefix") {
          return Yup.string().required("Name prefix is required");
        }
        if (key === "first_name") {
          return Yup.string().required("First name is required");
        }
        if (key === "last_name") {
          return Yup.string().required("Last name is required");
        }
      })
    )
  );

const ClaimFilterSchema = () => {
  return Yup.object().shape({
    doctor: Yup.string().required("Patient Insurance is required"),
    country: Yup.string().required("Country Required"),
    phoneCode: Yup.string().required("Phone Code is Required"),
    phoneNumber: Yup.string().required("Phone No. is Required"),
    institutionHospitalClinic: Yup.string().required("filled should not be blank"),
  });
};

const sentClaimFilterSchema = () => {
  return Yup.object().shape({
    startDate: Yup.string().required("Filled is required"),
    endDate: Yup.string().required("Filled is required"),
    // patient: Yup.string().required('Patient Insurance is required'),
    // status: Yup.string().required('Status is required'),
  });
};
const Schema = {
  LoginSchema,
  ForgotSchema,
  ResetPasswordSchema,
  setPasswordAdminSchema,
  changePasswordSchema,
  CheckPasswordSchema,
  createProfileSchema,
  editProfileSchema,
  fieldArrayFormSchema,
  AddMedicineSchema,
  workTimeSchema,
  selecteLocation,
  PatientInsuranceSchema,
  memberSearchSchema,
  TranscribePrescriptionSchema,
  InstitutionHopitalClinicSchema,
  DoctorDetailsSchema,
  ClaimFilterSchema,
  sentClaimFilterSchema,
  TranscribeSchema,
  addMedicineDoctorPrescriptionSchema,
};

export default Schema;
