import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Box,
  Typography,
  Button,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { createStyles, makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModal } from "../../modal";
import Utils from "../../utils";
import { getDoctorPrescriptions, onSendOtp, declinePrescription, getClaimBalance, sendOTPandVerify } from "./action";
import VerificationModal from "../../components/modal/verificationModal";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import moment from "moment";
import { getUserIdFromLS } from "../../utils/commonFunctions";
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "#fff";
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 450,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 2,
  px: 4,
  pb: 3,
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    button: {
      display: "flex",
      justifyContent: "center",
    },
    buttonOpen: {
      backgroundImage: "linear-gradient(281deg, #53c392 104%, #152567 -1%)",
      cursor: "pointer",
    },
    buttonDecline: {
      marginLeft: "16px",
      boxShadow: "30px",
      cursor: "pointer",
    },
  })
);
function TableComponent() {
  const classes = useStyles();
  const [deleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { data, limit, count, userId, phoneNumber, orderId, page, userName } = useSelector(
    (state: ReducersModal) => state.DoctorPrescriptionsReducer
  );
  const handleOpen = (e: any, id: any, order_id: any) => {
    dispatch({
      type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
      payload: {
        userId: id,
      },
    });
    setDeleteId(order_id);
    // e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // const [page, setPage] = useState(1);

  const handleChangePage = (value: any) => {
    dispatch({
      type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
      payload: {
        page: value + 1,
      },
    });
    // setPage(value + 1);
  };

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
      payload: {
        offset: page * limit - limit,
      },
    });
    dispatch(getDoctorPrescriptions());
  }, [page]);

  const handleChangeRowsPerPage = (evt: any) => {
    dispatch({
      type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
      payload: {
        limit: +evt.target.value,
        offset: 0,
      },
    });
    dispatch(getDoctorPrescriptions());
    // setRowsPerPage(10)
  };

  const handleVerification = (phoneNumber: any, id: any, userId: any, userName: any) => {
    dispatch({
      type: Utils.ActionName.CREATE_CLAIM_PRESCRIPTION,
      payload: {
        activeStep: 1,
      },
    });
    // dispatch(getClaimBalance(id))
    dispatch(sendOTPandVerify(id, phoneNumber, userId, userName ));
    // dispatch({
    //   type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
    //   payload: {
    //     showVerification: true,
    //     userId: userId,
    //     orderId: id,
    //     phoneNumber: phoneNumber,
    //     userName,
    //   },
    // });
  };

  const handleDelete = () => {
    const pharmId = getUserIdFromLS();
    if (deleteId) {
      dispatch(declinePrescription(deleteId, pharmId));
    }

    setOpen(!open);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Request ID</TableCell>
              <TableCell>Doctor Name</TableCell>
              <TableCell>Patient Name</TableCell>
              <TableCell>Insurance Company</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data.map((data: any) => {
                return (
                  <TableRow key={data.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>{data.order_id}</TableCell>
                    <TableCell>{data.doctor_name}</TableCell>
                    <TableCell>
                      {+data?.pharmacy_consultation
                        ? `${JSON.parse(data?.beneficiary_details)?.beneficiary_first_name} ${
                            JSON.parse(data?.beneficiary_details)?.beneficiary_last_name
                          }`
                        : data?.user_name}
                    </TableCell>
                    <TableCell>{data.insurance_name}</TableCell>
                    <TableCell>{data.claim_date ? moment(data.claim_date).format("DD/MM/YYYY") : "N/A"}</TableCell>

                    <TableCell>
                      <Box className={classes.button}>
                        <Box className={classes.buttonOpen}>
                          <button
                            style={{
                              backgroundImage: "linear-gradient(281deg, #53c392 -1%, #152567 100%)",
                              color: "var(--white)",
                              outline: "none !important",
                              height: "32px",
                              border: "none",
                              boxShadow: "10px 0px 10px 0px #fff",
                              borderRadius: "6px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleVerification(
                                data.user_phone,
                                data.order_id,
                                data.user_id,
                                +data?.pharmacy_consultation
                                  ? `${JSON.parse(data?.beneficiary_details)?.beneficiary_first_name} ${
                                      JSON.parse(data?.beneficiary_details)?.beneficiary_last_name
                                    }`
                                  : data?.user_name
                              )
                            }
                          >
                            Open Prescription
                          </button>{" "}
                        </Box>
                        <Box className={classes.buttonDecline}>
                          <button
                            style={{
                              backgroundColor: "var(--bright-light-red)",
                              color: "var(--white)",
                              outline: "none !important",
                              height: "32px",
                              border: "none",
                              boxShadow: "10px 0px 10px 0px #fff",
                              borderRadius: "6px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              handleOpen(e, data.user_id, data?.order_id);
                            }}
                          >
                            Decline
                          </button>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow key={1} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {" "}
                <TableCell>{"No Prescription Found"}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data?.length > 0 && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={limit}
              page={page - 1}
              onPageChange={(e, value): any => handleChangePage(value)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={<span>Rows:</span>}
              sx={{
                ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                  fontWeight: "bold",
                  color: "primary",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      )}
      <VerificationModal userId={userId} phoneNumber={phoneNumber} orderId={orderId} userName={userName} />
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to decline?
          </Typography>
          <Button variant="outlined" color="primary" sx={{ mt: 6, ml: 19 }} onClick={handleClose}>
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 6, ml: 4, color: "#fff" }}
            onClick={() => handleDelete()}
          >
            Yes
          </Button>
        </Box>
      </StyledModal>
    </>
  );
}

export default TableComponent;
