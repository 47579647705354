import { Typography, Theme, Grid, Box, Avatar, MenuItem } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import CustomFileUpload from "../../components/formsUi/inputFile/customFileUpload";
import FormField, { fontSizeData } from "../../schema/formField";
import ImgCrop from "../../components/imgCrop";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  checkImageWAH,
  convertBannerWebsiteManagementPayload,
  uploadDocumentImage,
} from "../../utils/commonFunctions";
import Utils from "../../utils";
import { LocalImages } from "../../utils/images";
import ButtonWrapper from "../../components/formsUi/button";
import InputColor from "../../components/formsUi/textFiled/InputColor";
import TextArea from "../../components/formsUi/textFiled/textArea";
import SelectWrapper from "../../components/formsUi/select";
import { getWebsiteManagement, updateWebsiteManagement } from "./action";
import { ReducersModal } from "../../modal";
const API_URL = Utils.constants.API_URL;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      "&.MuiTypography-root": {
        font: `normal ${theme.typography.fontWeightBold} ${theme.spacing(
          2
        )} Roboto, sans-serif`,
      },
    },
    avatarCover: {
      position: "relative",
      display: "inline-block",
    },
    avatar: {
      boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.2)",
      "&.MuiAvatar-root": {
        width: theme.spacing(22),
        height: "100%",
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
      },
    },
    closeIcon: {
      position: "absolute",
      top: -10,
      right: -10,
      cursor: "pointer",
    },
    textArea: {
      margin: theme.spacing(1, 0),
      maxWidth: "100%",
      minWidth: "100%",
      minHeight: "120px !important",
    },
  })
);

export type ImageType = "banner" | "logoImg";

function ExclusionsList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState(FormField.bannerManagement);
  const [openImgCrop, setOpenImgCrop] = useState(false);
  const [openImgCropSrc, setOpenImgCropSrc] = useState("");
  const [type, setType] = useState<ImageType>("banner");
  const { banner_data, logo } = useSelector(
    (state: ReducersModal) => state.websiteManagementReducer
  );
  const {
    banner_bg_image,
    bg_color,
    description,
    heading,
    heading_color,
    heading_fz,
    sub_heading_color,
    sub_heading_fz,
  } = banner_data;

  useEffect(() => {
    dispatch(getWebsiteManagement());
  }, [dispatch]);

  useEffect(() => {
    if (banner_data) {
      setInitialValue({
        ...initialValue,
        bgImg: banner_bg_image,
        bgHeading: heading,
        bgDiscription: description,
        bgColor: bg_color,
        bgHeadingColor: heading_color,
        bgSubheadingColor: sub_heading_color,
        headingFontSize: heading_fz,
        subheadingFontSize: sub_heading_fz,
        logoImg: logo,
      });
    }
  }, [banner_data]);

 

  const handleBgFile = (file: string, type: ImageType) => {
    setType(type);
    setOpenImgCropSrc(file);
    handleOpenImgCrop();
  };

  const handleOpenImgCrop = () => {
    setOpenImgCrop(true);
  };
  const handleCloseImgCrop = () => {
    setOpenImgCrop(false);
  };

  const addCropperProfilePicture = (files: any, imgType: ImageType) => {
    return new Promise((resolve, reject) => {
      if (!imgType) return;
      dispatch(globalLoaderTrue());
      uploadDocumentImage(files.split(",")[1])
        .then((res: any) => {
          if (res?.status === 200) {
            if (imgType === "banner")
              setInitialValue({ ...initialValue, bgImg: res?.data?.image });
            else if (imgType === "logoImg")
              setInitialValue({ ...initialValue, logoImg: res?.data?.image });
            resolve("upload profile picture");
            dispatch(globalLoaderFalse());
          }
        })
        .catch((err: any) => {
          Utils.showAlert(
            2,
            err?.response?.data?.messages && err?.response?.data?.messages[0]
          );
          reject(
            err?.response?.data?.messages && err?.response?.data?.messages[0]
          );
          dispatch(globalLoaderFalse());
        });
    });
  };
  
  
  return (
    <>
     <div className="page-contact-info">
					<ul className="col-lg-4" >
						<li><h2>Jubilee Health Insurance:</h2><p style={{fontSize: '20px'}}><a href="https://admin.liviaapp.com/Jubilee-KQ-Exclusions.pdf" target="blank" style={{color: 'darkblue'}}>Click here to view Exclusions</a></p></li>
						<li><h2>Kenya Airways (Jubilee Health insurance):</h2><p style={{fontSize: '20px'}}><a href="https://admin.liviaapp.com/Jubilee-KQ-Exclusions.pdf" target="blank" style={{color: 'darkblue'}}>Click here to view Exclusions</a></p></li>
						<li><h2>Britam Insurance:</h2><p style={{fontSize: '20px'}}><a href="https://admin.liviaapp.com/BRITAM_EXCLUSIONS.pdf" target="blank" style={{color: 'darkblue'}}>Click here to view Exclusions</a></p></li>
					</ul>
					<ul className="col-lg-4" >
						<li><h2>Old Mutual Insurance</h2><p style={{fontSize: '20px'}}><a href="https://admin.liviaapp.com/OLDMUTUAL.pdf" target="blank" style={{color: 'darkblue'}}>Click here to view Exclusions</a></p></li>
						<li><h2>EABL (Old Mutual Insurance)</h2><p style={{fontSize: '20px'}}><a href="https://admin.liviaapp.com/OLDMUTUAL.pdf" target="blank" style={{color: 'darkblue'}}>Click here to view Exclusions</a></p></li>
						<li><h2>CIC Insurance:</h2><p style={{fontSize: '20px'}}><a href="https://admin.liviaapp.com/CIC.pdf" target="blank" style={{color: 'darkblue'}}>Click here to view Exclusions</a></p></li>
					</ul>
				    <ul className="col-lg-4">
						<li><h2>Liaison Insurance:</h2><p style={{fontSize: '20px'}}><a href="https://admin.liviaapp.com/LIAISON.pdf" target="blank" style={{color: 'darkblue'}}>Click here to view Exclusions</a></p></li>
						<li><h2>First Assurance insurance:</h2><p style={{fontSize: '20px'}}><a href="https://admin.liviaapp.com/FA.pdf" target="blank" style={{color: 'darkblue'}}>Click here to view Exclusions</a></p></li>
					</ul>
			</div>
    </>
  );
}

export default ExclusionsList;
