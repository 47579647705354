export const steps = ["Create Profile", "Work Time"];
export const steps2 = ["Edit Profile", "Work Time"];

export const claimSteps = [
  "Patient Information",
  "Transcribe Prescription",
  "Patient Verification",
  "Upload Prescription",
];

export const statusArray = [
  { id:2,name:"Awaiting Pre-authorisation" },
  { id:3,name:"Accepted" },
  { id:4,name:"Declined" },
  { id:6,name:"In Process" },
  { id:7,name:"Loaded" },
  { id:8,name:"Payment Ready" },
  { id:9,name:"Settled" },
];

export const payment_status = [
  { id:3,name:"Loaded" },
  { id:6,name:"Refund" },
  { id:8,name:"Blocked funds" },
  { id:9,name:"Fund in withdrawal process" },
  { id:10,name:"Payment done" },
  { id:7,name:"Ready for Payment" },
];
