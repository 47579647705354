import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from '@react-google-maps/api';
import { makeStyles, createStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import Geocode from 'react-geocode';
import Utils from '../../../utils';
import { Box, Theme } from '@mui/system';
import { Button, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { getEditMyPersonalProfileInfo } from '../action';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingDiv: {
      borderBottom: `2px solid ${theme.palette.secondary.light}`,
    },
    heading: {},
    locationDiv: {
      borderBottom: `2px solid ${theme.palette.secondary.light}`,
    },
  })
);

const REACT_APP_GOOGLE_MAPS_API_KEY = 'AIzaSyDCJQ_VirRKzeRcGlnk4PWQpcuSiKUqM28';
Geocode.setApiKey(REACT_APP_GOOGLE_MAPS_API_KEY);

const MapContainer = ({ handleClose, editMap }: any) => {
  const classes = useStyles();
  const [libraries] = useState<
    ['places' | 'drawing' | 'geometry' | 'localContext' | 'visualization']
  >(['places']);
  const [currentPosition, setCurrentPosition] = useState({
    lat: -3.745,
    lng: -38.523,
  });

  const [autocomplete, setAutoComplete] = useState<any>({});
  const { setFieldValue } = useFormikContext();
  const [physicalAddressField, physicalAddressMata] =
    useField('physical_address');
  const [latitutdeField, latitutdeMata] = useField('latitude');
  const [longitudeField, longitudeMata] = useField('longitude');
  const dispatch = useDispatch();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries, // ,
  });
  const { latitude, longitude } = useSelector(
    (state: any) => state.EditMyProfilePersonalInfoReducer
  );

  console.log(currentPosition);
  // console.log("latitude", latitude, "longitude", longitude);

  useEffect(() => {
    if (latitude && longitude) {
      setCurrentPosition({
        lat: Number(latitude),
        lng: Number(longitude),
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!editMap) {
      navigator.geolocation.getCurrentPosition(success);
    }
  }, [editMap]);

  useEffect(() => {
    if (currentPosition.lat && currentPosition.lng) {
      // console.log("currentPosition", currentPosition);
      setFieldValue('latitude', currentPosition.lat);
      setFieldValue('longitude', currentPosition.lng);
    }
  }, [currentPosition.lat, currentPosition.lng]);

  const success = (position: any) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    if (currentPosition.lat && currentPosition.lng) {
      Geocode.fromLatLng(currentPosition.lat, currentPosition.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setFieldValue('physical_address', address);
          // console.log(address);
        },
        (error) => {
          console.error(error);
        }
      );
      setCurrentPosition(currentPosition);
    }
  };

  const onMarkerDragEnd = (e: any) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    if (lat && lng) {
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setFieldValue('physical_address', address);
          // console.log(address);
        },
        (error) => {
          console.error(error);
        }
      );
      setCurrentPosition({ lat, lng });
      dispatch({
        type: Utils.ActionName.EDIT_MY_PROFILE_PERSONAL_INFO,
        payload: {
          latitude: lat,
          longitude: lng,
        },
      });
    }
  };

  const onLoad = (autocomplete: any) => {
    setAutoComplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const getPlace = autocomplete.getPlace();
      const lat = getPlace.geometry.location.lat();
      const lng = getPlace.geometry.location.lng();
      setFieldValue('physical_address', getPlace.formatted_address);
      setCurrentPosition({ lat, lng });
      // console.log(getPlace);
    } else {
      // console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleUseAddresClick = () => {
    if (
      physicalAddressMata &&
      physicalAddressMata.touched &&
      physicalAddressMata.error
    ) {
      Utils.showAlert(2, physicalAddressMata.error);
      console.log(physicalAddressMata, physicalAddressField);
      return;
    } else if (latitutdeMata && latitutdeMata.touched && latitutdeMata.error) {
      Utils.showAlert(2, latitutdeMata.error);
      // console.log(latitutdeMata, latitutdeField);
      return;
    } else if (longitudeMata && longitudeMata.touched && longitudeMata.error) {
      Utils.showAlert(2, longitudeMata.error);
      // console.log(longitudeMata, longitudeField);
      return;
    } else {
      handleClose();
    }
  };

  // console.log("autocomplete", autocomplete);
  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={13}
        center={currentPosition}
      >
        {currentPosition.lat && (
          <Marker
            key={'h'}
            draggable={true}
            position={currentPosition}
            onDragEnd={(e) => onMarkerDragEnd(e)}
            animation={google.maps.Animation.BOUNCE}
          />
        )}
        {
          <Autocomplete
            onLoad={onLoad}
            restrictions={{ country: 'Ken' }}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              placeholder="Enter location"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `48px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: 'absolute',
                left: '50%',
                marginLeft: '-120px',
              }}
            />
          </Autocomplete>
        }
      </GoogleMap>
    );
  };

  return isLoaded ? (
    <Box className="bg-white" sx={{ p: 0 }}>
      <div className={classes.headingDiv}>
        <Box sx={{ p: 2 }}>
          <Typography className="semi-bold">
            Drag and drop marker to the location of your choice.
          </Typography>
        </Box>
      </div>
      {renderMap()}
      <div>
        <div className={classes.locationDiv}>
          <Box sx={{ p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              label="Location"
              {...physicalAddressField}
              inputProps={{ readOnly: true }}
            />
          </Box>
        </div>
        <Box sx={{ mb: 1.5 }}>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              sx={{ color: 'var(--white)', textTransform: 'capitalize' }}
              variant="contained"
              endIcon={<CheckIcon />}
              size="small"
              onClick={handleUseAddresClick}
            >
              Use this Address
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  ) : (
    <div>"Loading..."</div>
  );
};
export default MapContainer;
