import { Theme, Typography, Box } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashBoard } from "./action";
import CardComponent from "./Card";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboardContent: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
  })
);

export default function Card() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashBoard());
  }, []);

  const {
    total_claims,
    total_claims_amount,
    accepted_claims,
    accepted_claims_amount,
    review_claims,
    review_claims_amount,
    decline_claims,
    decline_claims_amount,
  } = useSelector((state: any) => state.DashBoardReducer);

  return (
    <div className="page-dashboard">
      <div className={`bg-white ${classes.dashboardContent}`}>
        <CardComponent
          title={"Total Claims"}
          value={
            <Typography variant="h3">
              {total_claims ? total_claims : "N/A"}
            </Typography>
          }
          content={`Amount: ${
            total_claims_amount ? total_claims_amount : "N/A"
          } KES`}
          color="red"
        />
        <CardComponent
          title={"Claims sent to Insurance"}
          value={
            <Typography variant="h3">
              {accepted_claims ? accepted_claims : "N/A"}
            </Typography>
          }
          content={`Amount: ${
            accepted_claims_amount ? accepted_claims_amount : "N/A"
          } KES`}
          color="blue"
        />
        <CardComponent
          title={"Template Claims"}
          value={
            <Typography variant="h3">
              {review_claims ? review_claims : "N/A"}
            </Typography>
          }
          content={`Amount: ${
            review_claims_amount ? review_claims_amount : "N/A"
          } KES`}
          color="persian"
        />
        <CardComponent
          title={"Declined Claims"}
          value={
            <Typography variant="h3">
              {decline_claims ? decline_claims : "N/A"}
            </Typography>
          }
          content={`Amount: ${
            decline_claims_amount ? decline_claims_amount : "N/A"
          } KES`}
          color="greenish"
        />
      </div>
    </div>
  );
}
